import React from "react";

const ChevronSvg = () => (
  <svg
    width="5"
    height="9"
    viewBox="0 0 5 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M1 8L4 4.5L1 1"
      stroke="#3b4238"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronSvg;
