/**
 * A "critical" icon is any svg that's included above the fold for all users on most pages"
 */

/* eslint-disable react/destructuring-assignment */
import React from "react";
import spritePath from "./sprite.svg";

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  name: string;
  height?: string;
  width?: string;
}

const defaults = {
  height: "1rem",
  width: "1rem",
};

export default (props: IconProps) => {
  const { name, ...rest } = props;
  return (
    <svg className="svg" {...{ ...defaults, ...rest }}>
      <use xlinkHref={`${spritePath}#${name}`} />
    </svg>
  );
};
