import React from "react";

interface SkeletonContainerProps {
  classNames: ClassTypes;
  style: React.CSSProperties;
}

const SkeletonContainer = ({ classNames, style }: SkeletonContainerProps) => {
  return <div className={classNames} style={style} />;
};

export default SkeletonContainer;
