import { cls } from "cls";
import React from "react";
import CartIcon from "./Icons/CartIcon";

const CartButton = ({ itemsInCart }: { itemsInCart: number | undefined }) => {
  return (
    <a
      aria-label={`Your cart has ${itemsInCart} items. View your cart.`}
      href="/cart/"
      className={cls(
        "group",
        "flex",
        "flex-nowrap",
        "rounded-tl",
        "rounded-bl",
        "overflow-hidden"
      )}
      data-testid="cart-button"
    >
      <span
        className={`${cls(
          "flex",
          "items-center",
          "px-2",
          "transition",
          "ease-in-out",
          "duration-300"
        )} h-[30px] md:h-[34px] lt:h-10 bg-green-700 hover:bg-green-800 lt:bg-green-500 lt:hover:bg-green-800`}
      >
        <CartIcon />
      </span>
      <span
        id="cartItemCountSpan"
        style={{
          minWidth: "28px",
        }}
        className={`${cls(
          "bg-white",
          "rounded-tr",
          "rounded-br",
          "flex",
          "items-center",
          "font-bold",
          "text-base",
          "leading-5",
          "px-2",
          "text-gray-800",
          "justify-center",
          "box-border",
          "md:border-gray-400",
          "md:border",
          "md:border-l-0",
          "md:border-solid"
        )} h-[30px] md:h-[34px] lt:h-10 tracking-[.02em]`}
      >
        {itemsInCart}
      </span>
    </a>
  );
};

export default CartButton;
