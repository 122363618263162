import React from "react";

const HelpSvg = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M11.0002 20.1667C5.93741 20.1667 1.8335 16.0627 1.8335 11C1.8335 5.93725 5.93741 1.83333 11.0002 1.83333C16.0629 1.83333 20.1668 5.93725 20.1668 11C20.1668 16.0627 16.0629 20.1667 11.0002 20.1667ZM11.0002 18.3333C12.9451 18.3333 14.8103 17.5607 16.1856 16.1854C17.5609 14.8102 18.3335 12.9449 18.3335 11C18.3335 9.05507 17.5609 7.18981 16.1856 5.81455C14.8103 4.43928 12.9451 3.66666 11.0002 3.66666C9.05524 3.66666 7.18998 4.43928 5.81471 5.81455C4.43945 7.18981 3.66683 9.05507 3.66683 11C3.66683 12.9449 4.43945 14.8102 5.81471 16.1854C7.18998 17.5607 9.05524 18.3333 11.0002 18.3333ZM10.0835 13.75H11.9168V15.5833H10.0835V13.75ZM11.9168 12.2421V12.8333H10.0835V11.4583C10.0835 11.2152 10.1801 10.9821 10.352 10.8101C10.5239 10.6382 10.757 10.5417 11.0002 10.5417C11.2606 10.5416 11.5156 10.4677 11.7356 10.3284C11.9556 10.1891 12.1316 9.99016 12.243 9.75478C12.3543 9.5194 12.3966 9.25722 12.3648 8.99877C12.333 8.74031 12.2285 8.4962 12.0633 8.29483C11.8982 8.09346 11.6793 7.94312 11.4321 7.8613C11.1849 7.77948 10.9195 7.76954 10.6669 7.83264C10.4142 7.89574 10.1847 8.02929 10.005 8.21774C9.82529 8.4062 9.70279 8.64181 9.65175 8.89716L7.85325 8.53691C7.96474 7.97966 8.22221 7.462 8.59934 7.03687C8.97647 6.61173 9.45972 6.29436 9.9997 6.1172C10.5397 5.94005 11.117 5.90946 11.6727 6.02856C12.2284 6.14766 12.7425 6.41218 13.1624 6.79508C13.5824 7.17799 13.8931 7.66553 14.0628 8.20789C14.2326 8.75024 14.2553 9.32794 14.1286 9.88194C14.0019 10.4359 13.7304 10.9464 13.3418 11.361C12.9532 11.7757 12.4615 12.0797 11.9168 12.2421Z"
      fill="#3b4238"
    />
  </svg>
);

export default HelpSvg;
