import SHA256 from "crypto-js/sha256";
import { logException } from "../../../__alias__/error-tracking";
import type {
  Tag,
  PinterestEventUserData,
  PinterestUserAnalyticsData,
  MarketingApi,
} from "../MarketingDataDefinitions";
import Environment from "../../../common/Environment";
import MarketingEventHelpers from "../../MarketingHelpers/MarketingEventHelpers";
import { getUserForPinterestEvent } from "../../../userAnalytics";

export default class PinterestApiEvent implements MarketingApi {
  eventName: string;

  eventTime = MarketingEventHelpers.getCurrentTimeStamp();

  actionSource = "Website";

  eventId = MarketingEventHelpers.generateUniqueEventId();

  eventSourceUrl = MarketingEventHelpers.removeEmailFromUrl(
    new URL(window.location.href)
  );

  correspondingTagEvent: Tag;

  user: PinterestEventUserData;

  protected stashEndpoint = new URL(
    `https://${Environment.stashDomainWithEnvironment}/pinterestConversion`
  );

  constructor(eventName: string, associatedTagEvent: Tag) {
    this.eventName = eventName;
    this.correspondingTagEvent = associatedTagEvent;
    this.correspondingTagEvent.eventId = this.eventId;

    const user: PinterestUserAnalyticsData = getUserForPinterestEvent();

    this.user = {
      ClientIpAddress: user.ip,
      ClientUserAgent: user.user_agent,
    };

    if (user?.em) {
      this.user.Em = [SHA256(user.em).toString()];
    }

    if (user?.fn) {
      this.user.Fn = [SHA256(user?.fn).toString()];
    }

    if (user?.ln) {
      this.user.Ln = [SHA256(user?.ln).toString()];
    }

    if (user?.ph) {
      this.user.Ph = [SHA256(user?.ph).toString()];
    }

    if (user?.external_id) {
      this.user.ExternalId = [SHA256(user.external_id).toString()];
    }

    if (user?.maids) {
      this.user.hashedMaids = [SHA256(user.maids).toString()];
    }

    if (user?.ct) {
      this.user.Ct = [SHA256(user.ct).toString()];
    }

    if (user?.st) {
      this.user.St = [SHA256(user.st).toString()];
    }

    if (user?.country) {
      this.user.Country = [SHA256(user.country).toString()];
    }

    if (user?.zp) {
      this.user.Zp = [SHA256(user.zp).toString()];
    }
  }

  sendEvent(): void {
    this.makeApiRequest();
    this.correspondingTagEvent.sendEvent();
  }

  protected formatDataForStashApiRequest() {
    const pinterestStashRequest = {
      EventName: this.eventName,
      EventTime: this.eventTime,
      ActionSource: this.actionSource,
      EventId: this.eventId,
      EventSourceUrl: this.eventSourceUrl,
      UserData: {
        ...this.user,
      },
    };

    return pinterestStashRequest;
  }

  protected async makeApiRequest(): Promise<void> {
    try {
      const result = await fetch(this.stashEndpoint, {
        method: "POST",
        body: JSON.stringify(this.formatDataForStashApiRequest()),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!result.ok) {
        await logException(
          new Error(`Pinterest Stash Error: "${await result.text()}"`)
        );
      }
    } catch (error) {
      await logException(new Error(`Pinterest Fetch Error:"${error}"`));
    }
  }
}
