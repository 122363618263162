import React from "react";

interface PhoneBadgeProps {
  classNames?: string;
}

const PhoneBadge = ({ classNames }: PhoneBadgeProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="
    http://www.w3.org/2000/svg"
      className={classNames}
    >
      <path
        d="M4 2.35088C4 1.60481 4.60481 1 5.35088 1H10.6491C11.3952 1 12 1.60481 12 2.35088V13.6491C12 14.3952 11.3952 15 10.6491 15H5.35088C4.60481 15 4 14.3952 4 13.6491V2.35088Z"
        fill="#EAF3E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50586 2.35076C3.50586 1.33131 4.33229 0.504883 5.35174 0.504883H10.65C11.6694 0.504883 12.4959 1.33131 12.4959 2.35076V13.649C12.4959 14.6685 11.6694 15.4949 10.65 15.4949H5.35174C4.33229 15.4949 3.50586 14.6685 3.50586 13.649V2.35076ZM5.35174 1.49488C4.87905 1.49488 4.49586 1.87807 4.49586 2.35076V13.649C4.49586 14.1217 4.87905 14.5049 5.35174 14.5049H10.65C11.1227 14.5049 11.5059 14.1217 11.5059 13.649V2.35076C11.5059 1.87807 11.1227 1.49488 10.65 1.49488H5.35174Z"
        fill="#1D5C00"
      />
      <path
        d="M8 13C8.55225 13 9 12.5524 9 12C9 11.4476 8.55225 11 8 11C7.44775 11 7 11.4476 7 12C7 12.5524 7.44775 13 8 13Z"
        fill="#1D5C00"
      />
      <path
        d="M6 3.5C6 3.22396 6.19189 3 6.42857 3H9.57143C9.80811 3 10 3.22396 10 3.5C10 3.77604 9.80811 4 9.57143 4H6.42857C6.19189 4 6 3.77604 6 3.5Z"
        fill="#1D5C00"
      />
    </svg>
  );
};

export default PhoneBadge;
