import { cls } from "cls";

const sharedButtonStyles = cls(
  "hover:bg-gray-200",
  "rounded",
  "border-none",
  "cursor-pointer",
  "inline-flex",
  "items-center",
  "font-semibold",
  "p-2",
  "text-gray-800",
  "transition",
  "ease-in-out",
  "duration-100"
);

export default sharedButtonStyles;
