/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const MostRecentOrder = t.iface([], {
  "orderNumber": t.opt("number"),
  "orderStatus": t.opt("string"),
  "orderItemCount": t.opt("number"),
});

export const StatusBarFlags = t.iface([], {
  "orderNumberEncrypted": t.opt("string"),
  "showStatusBar": t.opt("boolean"),
  "showMostRecentOrder": t.opt("boolean"),
  "showAbandonCartMessage": t.opt("boolean"),
});

export const AutoReorders = t.iface([], {
  "id": t.opt("number"),
  "name": t.opt("string"),
  "desiredOrderDate": t.opt("string"),
});

export const RepImage = t.iface([], {
  "imagePath": "string",
});

export const RepInfo = t.iface([], {
  "name": t.opt("string"),
  "phone": t.opt("string"),
  "email": t.opt("string"),
  "images": t.array("RepImage"),
});

export const Platinum = t.iface([], {
  "enabled": "boolean",
  "notifications": t.array("Notification"),
  "repInfo": "RepInfo",
});

export const Notification = t.iface([], {
  "dateModified": "string",
  "message": "string",
  "dateCreated": "string",
  "dateDeleted": "string",
  "link": "string",
  "id": "number",
  "type": "string",
  "isDeletableByUser": "number",
});

export const Country = t.iface([], {
  "canShip": t.opt("boolean"),
  "countryCode": t.opt("string"),
  "countryName": t.opt("string"),
});

export const AnalyticsData = t.iface([], {
  "uq_customer_id": t.opt("string"),
  "hashedUserEmailSha256": t.opt("string"),
  "hashed_customer_id": t.opt("string"),
  "platinum_id": t.opt("string"),
  "csp_user_id": t.opt("string"),
  "company_type": t.opt("string"),
  "member_type": t.opt("string"),
  "web_plus": t.opt("string"),
  "date_registered": t.opt("string"),
  "purchaser_role_title": t.opt("string"),
  "amount_spent": t.opt("string"),
  "is_internal": t.opt("string"),
  "is_on_vpn": t.opt("string"),
  "is_registered": t.opt("string"),
  "total_orders": t.opt("string"),
  "meals_served": t.opt("string"),
  "cuisine_type": t.opt("string"),
  "number_of_seats": t.opt("string"),
  "fr_type": t.opt("string"),
  "payment_type": t.opt("string"),
  "ab_test_bucket": t.opt("string"),
  "variationIds": t.opt("string"),
  "emailTrafficHashedId": t.opt("string"),
});

export const FacebookUserData = t.iface([], {
  "ct": t.opt("string"),
  "country": t.opt("string"),
  "em": t.opt("string"),
  "fn": t.opt("string"),
  "ln": t.opt("string"),
  "ph": t.opt("string"),
  "st": t.opt("string"),
  "zp": t.opt("string"),
});

export const Wishlist = t.iface([], {
  "listName": t.opt("string"),
  "num": t.opt("number"),
});

export const User = t.iface([], {
  "index": t.opt("string"),
  "firstName": t.opt("string"),
  "fullName": t.opt("string"),
  "companyName": t.opt("string"),
  "hasWebPlus": t.opt("boolean"),
  "invoices": t.opt("boolean"),
  "itemsInCart": t.opt("number"),
  "orderGuides": t.opt("boolean"),
  "pendingOrders": t.opt("number"),
  "quotes": t.opt("boolean"),
  "registered": t.opt("boolean"),
  "showNextag": t.opt("boolean"),
  "showRapidReorder": t.opt("boolean"),
  "token": t.opt("string"),
  "platinum": "Platinum",
  "country": "Country",
  "wishLists": t.array("Wishlist"),
  "emailAddress": t.opt("string"),
  "autoReorders": "AutoReorders",
  "hasPurchaseHistory": t.opt("boolean"),
  "analyticsData": "AnalyticsData",
  "billingPhoneNumber": t.opt("string"),
  "billingAddressLineTwo": t.opt("string"),
  "billingCountry": t.opt("string"),
  "facebookUserData": "FacebookUserData",
  "supervisorIndex": t.opt("string"),
  "csrfToken": t.opt("string"),
  "amountSpentOneThousandOrMore": t.opt("boolean"),
  "isCommercial": t.opt("boolean"),
  "isRewardsMember": t.opt("boolean"),
  "billingAddress": t.opt("string"),
  "billingCity": t.opt("string"),
  "billingStateProvince": t.opt("string"),
  "billingPostalCode": t.opt("string"),
  "canQuickCheckout": t.opt("boolean"),
  "isUsingUnsupportedBrowser": t.opt("boolean"),
});

const exportedTypeSuite: t.ITypeSuite = {
  MostRecentOrder,
  StatusBarFlags,
  AutoReorders,
  RepImage,
  RepInfo,
  Platinum,
  Notification,
  Country,
  AnalyticsData,
  FacebookUserData,
  Wishlist,
  User,
};
export default exportedTypeSuite;
