import { ThunkAction } from "StoreTypes";
import { Dispatch } from "redux";
import { updateChat } from "./chat";
import { updateUser } from "./user";
import { Chat, User } from "../connectUserAndChat";
import getTrackerData from "~/common/helpers/getTrackerData";

let resolveGetDynamicData: (value?: unknown) => void;

export const getDynamicData = new Promise((resolve) => {
  resolveGetDynamicData = resolve;
});

export const updateUserAndChatFromOrigin = (): ThunkAction => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: "UPDATE_USER_AND_CHAT_FROM_ORIGIN",
  });

  const {
    Referer: referer,
    EmailTrackingData: emailTrackingData,
  } = getTrackerData();

  const response = await fetch(
    `/api:edgecache/getdynamicdata/?referer=${referer}` +
      `&ltid=${emailTrackingData.LtId}` +
      `&dlid=${emailTrackingData.DlId}` +
      `&link=${emailTrackingData.Link}`,
    {
      credentials: "include",
    }
  );

  const { user, chat } = await response.json<{ user: User; chat: Chat }>();

  if (!user.skipSettingGDDFetch) {
    user.lastGetDynamicDataFetch = new Date().getTime();
  }

  const camelcaseKeysImport = import("camelcase-keys");
  const { default: camelcaseKeys } = await camelcaseKeysImport;

  user.wishLists = camelcaseKeys(user.wishLists);

  dispatch(updateUser(user));

  dispatch(updateChat(chat));

  resolveGetDynamicData();
};

export const isUserDataFresh = (user?: User): boolean => {
  if (user && user.skipSettingGDDFetch) {
    return true;
  }
  return new Date().getTime() - (user?.lastGetDynamicDataFetch || 0) < 2000;
};
