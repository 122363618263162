import { createStandardAction } from "typesafe-actions";
import { ThunkAction } from "StoreTypes";
import { Dispatch } from "redux";

import { Notification } from "../../models/User";

export const setPlatinumNotifications = createStandardAction(
  "SET_PLATINUM_NOTIFICATIONS"
)<Notification[]>();
export const deletePlatinumNotification = createStandardAction(
  "DELETE_PLATINUM_NOTIFICATIONS"
)<number>();

export const getPlatinumNotifications = (): ThunkAction => async (
  dispatch: Dispatch
) => {
  const camelcaseKeysImport = import("camelcase-keys");
  const response = await fetch("/api:edgecache/getusernotifications/", {
    credentials: "include",
  });
  const rawData = await response.json<Notification[]>();
  const { default: camelcaseKeys } = await camelcaseKeysImport;
  const formattedData = camelcaseKeys(rawData);

  dispatch(setPlatinumNotifications(formattedData));
};

export const removePlatinumNotification = (id: number): ThunkAction => async (
  dispatch: Dispatch
) => {
  dispatch(deletePlatinumNotification(id));

  const body = new FormData();
  body.append("method", "deleteNotification");
  body.append("id", String(id));

  await fetch("/object/Platinum.cfc", {
    method: "POST",
    body,
    credentials: "include",
  });

  dispatch(deletePlatinumNotification(id));
};
