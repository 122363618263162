export default function QueryStringParameterSearch(
  name: string,
  url = window?.location?.href
) {
  if (!url) {
    return null;
  }

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
