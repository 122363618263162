import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";
import { defaultUserObject } from "~/common/Store/reducers/user";
import { setRepImages } from "../../actions/user/repImages";

const platinumRepContactReducer = (
  state = defaultUserObject.platinum.repInfo,
  action: RootAction
) => {
  switch (action.type) {
    case getType(setRepImages):
      return { ...state, images: action.payload };
    default:
      return { ...state, images: state.images ?? [] };
  }
};

export default platinumRepContactReducer;
