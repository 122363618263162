import { createStandardAction } from "typesafe-actions";
import { ThunkAction } from "StoreTypes";

import { Dispatch } from "redux";
import { RepImage } from "../../models/User";

export const setRepImages = createStandardAction("SET_REP_IMAGES")<
  RepImage[]
>();

export const getRepImages = (): ThunkAction => async (dispatch: Dispatch) => {
  const camelcaseKeysImport = import("camelcase-keys");
  const response = await fetch("/api:edgecache/getuserrepimages/", {
    credentials: "include",
  });

  const json = await response.json<RepImage[]>();
  const { default: camelcaseKeys } = await camelcaseKeysImport;

  const data = camelcaseKeys(json);
  dispatch(setRepImages(data));
};
