import React from "react";
import { cls } from "cls";
import { connect } from "react-redux";
import {
  ChatState,
  RootState,
  UserState,
} from "~/common/Store/models/RootReadonly";
import SiteWideEventDayBanner from "./SiteWideEventDayBanner";
import AlertBannerLeftContainer from "./AlertBannerLeftContainer";
import AlertBannerRightContainer from "./AlertBannerRightContainer";
import { PageCreatorBanner } from "./PageCreatorBanner";
import PlusPreviewBanner from "./PlusPreviewBanner";
import PlusEssentialsBanner from "./PlusEssentialsBanner";

interface Props {
  user: UserState;
  mounted: boolean;
  chat: ChatState;
  checkout: boolean;
  isEventDayDataLoading: boolean;
  pageCreatorBanner: PageCreatorBanner | null;
  eventDayBannerVisible: boolean;
}

export interface CheckWidthProps {
  isDesktop: boolean;
  isSmallDesktop: boolean;
  isLargeDesktop: boolean;
  isLargeTablet: boolean;
  isTablet: boolean;
  isSmallTablet: boolean;
  isMobile: boolean;
}

const AlertBanner = ({
  user,
  mounted,
  chat,
  checkout,
  isEventDayDataLoading,
  pageCreatorBanner,
  eventDayBannerVisible,
}: Props) => {
  const isCustomerServiceMessageAvailable = Boolean(
    chat && chat.headerBanner && chat.headerBanner.message.length > 0
  );

  if (
    user.registered &&
    user.freeviewPromotion &&
    user.freeviewPromotion > 0 &&
    user.freeviewEndDate
  ) {
    return <PlusPreviewBanner user={user} checkout={checkout} />;
  }

  const adTypeId = user.webPlusHomePageAdIds?.[0]?.webPlusAdId;
  const showPlusEssentialsBanner = adTypeId && adTypeId > 0;

  if (showPlusEssentialsBanner) {
    return (
      <PlusEssentialsBanner
        chat={chat}
        user={user}
        mounted={mounted}
        checkout={checkout}
        isCustomerServiceMessageAvailable={isCustomerServiceMessageAvailable}
        adTypeId={adTypeId}
        pageCreatorBanner={pageCreatorBanner}
        eventDayBannerVisible={eventDayBannerVisible}
      />
    );
  }

  return (
    <div
      data-testid="alert-banner"
      className={cls(
        "lt:border",
        "lt:border-x-0",
        "lt:border-t-0",
        "lt:bg-gray-200",
        "bg-white",
        "border-0",
        "border-gray-200",
        "border-solid",
        "flex",
        "items-center",
        "justify-center",
        "lt:block",
        "box-border",
        "min-h-[32px]"
      )}
      style={
        pageCreatorBanner
          ? { backgroundColor: pageCreatorBanner.backgroundColor }
          : {}
      }
    >
      <div className={cls("container", "lt:mx-auto", "py-1.5")}>
        <div
          className={cls(
            "flex",
            "items-center",
            "justify-center",
            "lt:justify-between"
          )}
        >
          <AlertBannerLeftContainer
            chat={chat}
            eventDayBannerVisible={eventDayBannerVisible}
            mounted={mounted}
            isCustomerServiceMessageAvailable={
              isCustomerServiceMessageAvailable
            }
            isEventDayDataLoading={isEventDayDataLoading}
            checkout={checkout}
            pageCreatorTextColor={pageCreatorBanner?.textColor ?? ""}
          />
          {pageCreatorBanner && !checkout ? (
            <SiteWideEventDayBanner
              pageCreatorBanner={pageCreatorBanner}
              classNames={cls(
                "lt:absolute",
                "lt:left-1/2",
                user.hasWebPlus ? "lz:left-1/2" : "lz:left-[45%] lt:left-[40%]",
                "xl:left-1/2",
                "lt:-translate-x-1/2",
                "block",
                isCustomerServiceMessageAvailable && !checkout
                  ? "xl:hidden"
                  : ""
              )}
            />
          ) : null}
          {!checkout && (
            <AlertBannerRightContainer
              user={user}
              isCustomerServiceMessageAvailable={
                isCustomerServiceMessageAvailable
              }
              eventDayBannerVisible={eventDayBannerVisible}
              mounted={mounted}
              isEventDayDataLoading={isEventDayDataLoading}
              pageCreatorBanner={pageCreatorBanner}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState, ownProps: Props) => {
  if (!ownProps.mounted) {
    return {};
  }

  return state;
};

export default connect(mapStateToProps)(AlertBanner);
