/** @deprecated A new environment manager should be created that works for both SSR and CSR */
class Environment {
  public static get primary() {
    if (Environment.devOrLoc) return "dev";
    if (Environment.subdomain === "test") return Environment.subdomain;

    return "prod";
  }

  public static get subdomain() {
    if (window.location?.hostname === "localhost") return "localhost";
    if (window.location?.hostname?.search(/\b.coder.\b/) > -1) return "coder";

    const [, subdomain] = window.location?.hostname.split(".") ?? [];
    return subdomain;
  }

  /**
   * Lets you know whether your in dev or loc. If your not in either it returns false.
   */
  public static get devOrLoc() {
    // Use indexOf here because this file is used by raven-js before polyfills are loaded.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (
      ["localhost", "loc", "dev", "coder"].indexOf(Environment.subdomain!) >= 0
    )
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      return Environment.subdomain as "loc" | "dev";

    return false;
  }

  /**
   * Returns the current domain with dev or test subdomains added depending on environment.
   * This returns the domain without www. This also coerces loc to dev.
   * Examples: www.loc.webstaurantstore.com -> dev.webstaurantstore.com
   * www.dev.webstaurantstore.com -> dev.webstaurantstore.com
   * www.webstaurantstore.com -> webstaurantstore.com
   */
  public static get domainWithEnvironment() {
    let environment = "";

    if (Environment.devOrLoc) environment = "dev.";

    if (Environment.subdomain === "test") environment = "test.";

    return `${environment}webstaurantstore.com`;
  }

  public static get stashDomainWithEnvironment() {
    // eslint-disable-next-line prefer-destructuring
    const subdomain = Environment.subdomain;
    let stashEndpoint = "stash.webstaurantstore.com";

    if (
      (subdomain && subdomain?.search(/\bdev?(\d+)?\b/) > -1) ||
      (subdomain && subdomain?.search(/\bloc?(\d+)?\b/) > -1) ||
      (subdomain && subdomain === "coder") ||
      (subdomain && subdomain === "localhost")
    ) {
      stashEndpoint = "stash.dev.webstaurantstore.com";
    } else if (
      (subdomain && subdomain?.search(/\btest?(\d+)?\b/) > -1) ||
      (subdomain && subdomain?.search(/\bpreflight?(\d+)?\b/) > -1)
    ) {
      stashEndpoint = "stash.test.webstaurantstore.com";
    }

    return stashEndpoint;
  }

  public static get isProduction() {
    // eslint-disable-next-line prefer-destructuring
    const subdomain = Environment.subdomain || "";
    let isProd = true;

    if (
      (subdomain && subdomain?.search(/\bdev?(\d+)?\b/) > -1) ||
      subdomain?.search(/\bloc?(\d+)?\b/) > -1 ||
      subdomain?.search(/\btest?(\d+)?\b/) > -1 ||
      subdomain?.search(/\bpreflight?(\d+)?\b/) > -1 ||
      subdomain === "coder" ||
      subdomain === "localhost"
    ) {
      isProd = false;
    }

    return isProd;
  }
}

export default Environment;

type SubDomains = "localhost" | "loc" | "dev" | "test" | "prod";
const hostname = window?.location?.hostname ?? "";
const [, subdomain] = hostname.split(".");
const isLoc = subdomain === "loc";
const isDev = subdomain === "dev";
const isTest = subdomain === "test";
const isPreflight = hostname.includes("preflight");
const isProd = !isLoc && !isDev && !isTest;
const current: SubDomains = isProd ? "prod" : subdomain ?? "loc";

export const env = { current, isLoc, isDev, isTest, isProd, isPreflight };
