import React from "react";
import { UserState } from "~/common/Store/models/RootReadonly";

interface RewardsBannerTextProps {
  rewardsTextClassNames: string;
  shortTextClassNames: string;
  longTextClassNames: string;
  user: UserState;
}

const RewardsBannerText = ({
  rewardsTextClassNames,
  shortTextClassNames,
  longTextClassNames,
  user,
}: RewardsBannerTextProps) => {
  return user.isRewardsMember ? (
    <span className={rewardsTextClassNames} data-testid="rewards-my-account">
      View Rewards
    </span>
  ) : (
    <>
      <span className={shortTextClassNames} data-testid="rewards-short-text">
        Earn 3% Back<sup style={{ fontSize: "9px", lineHeight: 0 }}>*</sup>
      </span>
      <span className={longTextClassNames} data-testid="rewards-long-text">
        Earn 3% Back<sup style={{ fontSize: "9px", lineHeight: 0 }}>*</sup>{" "}
        &#38; Save on Plus
      </span>
    </>
  );
};

export default RewardsBannerText;
