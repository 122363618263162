/**
 * A "standard" icon is any svg that is used below the fold.
 */
import React from "react";
import spritePath from "./standard-sprite.svg";

export interface IconProps {
  className?: string;
  name: string;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
}

export default (properties: IconProps) => {
  const { name, ...rest } = properties;
  return (
    <svg aria-hidden="true" className="svg" {...rest}>
      <use xlinkHref={`${spritePath}#${name}`} />
    </svg>
  );
};
