import React from "react";

interface AccountIconProps {
  classNames?: string;
  style?: React.CSSProperties;
}

const AccountIcon = ({ classNames = "", style = {} }: AccountIconProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      style={style}
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.97037 1.38434C5.2107 1.38434 3.83203 2.8518 3.83203 4.59965C3.83203 6.33989 5.19871 7.80217 6.9474 7.81488C6.96476 7.81475 6.98215 7.81468 6.99957 7.81468C7.0035 7.81468 7.00743 7.81469 7.01136 7.81469C8.75141 7.79212 10.1087 6.33391 10.1087 4.59965C10.1087 2.8518 8.73005 1.38434 6.97037 1.38434ZM9.52155 8.33996C10.6406 7.51339 11.3785 6.1553 11.3785 4.59965C11.3785 2.03855 9.37851 0.0129089 6.97037 0.0129089C4.56223 0.0129089 2.56221 2.03855 2.56221 4.59965C2.56221 6.16574 3.31006 7.53161 4.44177 8.35653C3.61092 8.74464 2.9087 9.32817 2.34802 10.0643C1.29291 11.4496 0.777344 13.3222 0.777344 15.3272C0.777344 15.7059 1.0616 16.0129 1.41225 16.0129H12.5869C12.9375 16.0129 13.2218 15.7059 13.2218 15.3272C13.2218 13.3222 12.7062 11.4496 11.6511 10.0643C11.0824 9.31762 10.368 8.72795 9.52155 8.33996ZM6.94857 9.18633C6.95583 9.18637 6.9631 9.18639 6.97037 9.18639C6.9858 9.18639 7.0012 9.18631 7.01659 9.18614C8.65075 9.19082 9.85864 9.86971 10.6705 10.9355C11.3883 11.878 11.8257 13.1637 11.9285 14.6415H2.07066C2.17347 13.1637 2.6108 11.878 3.32867 10.9355C4.13485 9.87711 5.33163 9.20026 6.94857 9.18633Z"
        fill="#3b4238"
      />
    </svg>
  );
};

export default AccountIcon;
