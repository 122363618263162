import React from "react";
import ChildOrNull from "~/common/ChildOrNull";

interface MenuItemProps {
  href?: string;
  content?: string | JSX.Element[] | JSX.Element;
  divider?: boolean;
  name?: string;
  extraListClasses?: string;
}

const MenuItem = ({
  href = "",
  content = "",
  divider = false,
  name,
  extraListClasses = "",
}: MenuItemProps) => {
  return (
    <li className={`w-full ${extraListClasses}`}>
      <ChildOrNull condition={divider}>
        <div className="bg-gray-200 border-white border-solid border-b overflow-hidden h-px mt-2 mb-0.5" />
      </ChildOrNull>
      <a
        href={href}
        className="relative whitespace-nowrap flex items-center font-normal text-sm leading-snug py-1-1/2 px-2 text-gray-800 rounded hover:underline hover:bg-gray-100 active:bg-gray-100"
        data-testid={name}
      >
        {content}
      </a>
    </li>
  );
};

export default MenuItem;
