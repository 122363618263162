/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const Root = t.iface([], {
  "chat": "Chat",
  "user": "User",
});

const exportedTypeSuite: t.ITypeSuite = {
  Root,
};
export default exportedTypeSuite;
