import React from "react";

interface QuestionProps {
  classNames: ClassTypes;
  fillColor?: string;
}

const Question = ({ classNames, fillColor = "#1175D0" }: QuestionProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10ZM5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9ZM4.5 6.5H5.5V7.5H4.5V6.5ZM5.5 5.6775V6H4.5V5.25C4.5 5.11739 4.55268 4.99021 4.64645 4.89645C4.74021 4.80268 4.86739 4.75 5 4.75C5.14204 4.74999 5.28116 4.70965 5.40116 4.63366C5.52117 4.55768 5.61713 4.44918 5.67789 4.32079C5.73864 4.1924 5.76168 4.0494 5.74434 3.90842C5.727 3.76745 5.66998 3.63429 5.57992 3.52446C5.48986 3.41462 5.37046 3.33261 5.23561 3.28798C5.10077 3.24335 4.95602 3.23793 4.81821 3.27235C4.68041 3.30677 4.55521 3.37962 4.45718 3.48241C4.35916 3.5852 4.29234 3.71372 4.2645 3.853L3.2835 3.6565C3.34432 3.35254 3.48475 3.07019 3.69046 2.83829C3.89617 2.6064 4.15976 2.43329 4.45429 2.33666C4.74883 2.24003 5.06374 2.22334 5.36684 2.28831C5.66994 2.35327 5.95035 2.49756 6.17941 2.70641C6.40847 2.91527 6.57796 3.1812 6.67056 3.47703C6.76315 3.77286 6.77553 4.08797 6.70643 4.39015C6.63733 4.69234 6.48923 4.97075 6.27726 5.19693C6.06529 5.42311 5.79707 5.58896 5.5 5.6775Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default Question;
