import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";
import * as actions from "../actions/chat";
import { ChatState } from "../models/RootReadonly";

export const defaultChatObject: ChatState = { hours: { daily: [], lines: [] } };

export default (state = defaultChatObject, action: RootAction) => {
  switch (action.type) {
    case getType(actions.updateChat):
      return { ...action.payload };
    default:
      return state;
  }
};
