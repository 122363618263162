import React from "react";
import { connect } from "react-redux";
import { RootState, UserState } from "~/common/Store/models/RootReadonly";

interface Props {
  mounted: boolean;
  user: UserState;
}

const UnsupportedBrowserBanner = ({ user }: Props) => (
  <>
    {user.isUsingUnsupportedBrowser && (
      <div className="sitewide-alert" data-testid="unsupportedBrowserBanner">
        <span className="icon icon-attention" />
        Your browser is no longer supported. Please upgrade to a modern browser.
        We recommend{" "}
        <a href="http://www.mozilla.org/firefox">Mozilla Firefox</a> or{" "}
        <a href="http://www.google.com/chrome">Google Chrome</a>.
      </div>
    )}
  </>
);

const mapStateToProps = (state: RootState, props: Props) => {
  if (!props.mounted) return {};
  return state;
};

export default connect(mapStateToProps)(UnsupportedBrowserBanner);
