import React from "react";

interface ChevronProps {
  classNames?: ClassTypes;
  strokeColor?: string;
}

const Chevron = ({ classNames, strokeColor = "#3b4238" }: ChevronProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      width="5"
      height="8"
      viewBox="0 0 5 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8L4 4.5L1 1"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Chevron;
