import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";
import * as actions from "../../actions/abFeature";
import { AbFeatureState } from "../../models/RootReadonly";

export const defaultAbFeatureObject: AbFeatureState = {
  requests: [],
  userOptions: [],
};

export default (state = defaultAbFeatureObject, action: RootAction) => {
  switch (action.type) {
    case getType(actions.addToAbFeatureRequest):
      return {
        ...state,
        requests: state.requests.concat(action.payload),
      };
    case getType(actions.updateUserAbFeatureOptions):
      return {
        ...state,
        userOptions: state.userOptions.some(
          (x) => x.featureName === action.payload[0]?.featureName
        )
          ? state.userOptions
          : state.userOptions.concat(action.payload),
      };
    default:
      return {
        ...state,
        requests: [],
      };
  }
};
