import clsx from "clsx";

/** @deprecated Please use `clsx` instead. [Documentation](https://www.npmjs.com/package/clsx) */
const concat = (...classes: string[]): string => {
  return clsx(...classes);
};

/** @deprecated Please use `clsx` instead. [Documentation](https://www.npmjs.com/package/clsx) */
export const tw = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

export default concat;
