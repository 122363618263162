import type { Product } from "../../../userAnalytics";
import type { Tag } from "../../MarketingEvents/MarketingDataDefinitions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pintrk: any;
export default class PinterestTagAddToCartEvent implements Tag {
  eventName = "addtocart";

  private currency = "USD";

  eventId = "";

  private productAddedToCart?: Product;

  constructor(productsAddedToCart: Product[]) {
    [this.productAddedToCart] = productsAddedToCart;
  }

  sendEvent(): void {
    if (typeof pintrk === "function") {
      pintrk("track", this.eventName, {
        event_id: this.eventId,
        value: this.productAddedToCart?.price,
        order_quantity: this.productAddedToCart?.quantity,
        currency: this.currency,
        product_id: [this.productAddedToCart?.id],
      });
    }
  }
}
