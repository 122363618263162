import React from "react";
import { cls } from "cls";

interface Props {
  message?: string;
  iconClassToUse?: string;
  eventDayBannerVisible: boolean;
  isPlusEssentials?: boolean;
  pageCreatorTextColor?: string;
}

const parse = (message: string) => {
  const formattedMessage = message.replace("<br/>", " ");
  return <>{formattedMessage}</>;
};

const CustomMessage = ({
  iconClassToUse,
  message = "",
  eventDayBannerVisible,
  isPlusEssentials,
  pageCreatorTextColor,
}: Props) => {
  import("~/fonts/fontello/css/fontello-top.less");
  return (
    <a
      href="/ask.html"
      title="WebstaurantStore Support"
      id="chat-message"
      className={cls(
        isPlusEssentials ? "text-white" : "text-gray-800",
        "group",
        "hidden",
        "mr-2",
        "hover:underline",
        "leading-none",
        "lt:items-center",
        eventDayBannerVisible ? "hidden xl:flex" : "hidden lt:flex"
      )}
      data-testid="custom-message"
      style={
        pageCreatorTextColor && !isPlusEssentials
          ? { color: pageCreatorTextColor }
          : {}
      }
    >
      <span className={cls("align-middle")}>
        <i className={iconClassToUse} />
      </span>
      <span
        className={cls(
          "inline-block",
          "font-semibold",
          "text-xs",
          "leading-none",
          "ml-2",
          "align-middle"
        )}
      >
        {parse(message)}
      </span>
    </a>
  );
};

export default CustomMessage;
