type EmailTrackingData = {
  Link: string;
  LtId: string;
  DlId: string;
};

type TrackerData = {
  IsStoreFrontApp: boolean;
  TrackedPage: string;
  Referer: string;
  EmailTrackingData: EmailTrackingData;
};

const getTrackerData = (): TrackerData => {
  return {
    IsStoreFrontApp: confirmPageIsStoreFrontApp(),
    TrackedPage: formatPageLinkForTracker(),
    Referer: document.referrer,
    EmailTrackingData: getEmailTrackingData(),
  };
};

const confirmPageIsStoreFrontApp = (): boolean => {
  let isSFApp = false;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const tPageDiv = document.querySelector("#tpage") as HTMLElement;
  if (tPageDiv && tPageDiv.dataset.sf === "1") {
    isSFApp = true;
  }

  return isSFApp;
};

export const formatPageLinkForTracker = (pageLink?: string) => {
  let tPage = pageLink || window.location.href;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const tPageDiv = document.querySelector("#tpage") as HTMLElement;
  if (tPageDiv) {
    tPage = tPageDiv.dataset.page || "";
  }

  const urlParts = tPage.split("?");
  let link = "";
  let queryString = "";

  if (urlParts[0]) {
    if (urlParts[1]) {
      queryString = `?${urlParts[1].substring(0, 68)}`;
      link = urlParts[0].substring(0, 29);
    } else {
      link = urlParts[0].substring(0, 99);
    }
  }
  return link + queryString;
};

const getEmailTrackingData = (): EmailTrackingData => {
  const url = window.location.href;
  let trackingParamsExist = false;
  const urlSplit = url.split("?");
  const queryString = urlSplit[1];

  if (queryString) {
    trackingParamsExist =
      queryString.includes("ltid") &&
      queryString.includes("dlid") &&
      !queryString.includes("emeop");
  }

  if (queryString && trackingParamsExist) {
    const queryParams = getEmailLinkTrackingParams(queryString);
    const page = encodeURIComponent(window.location.href);

    return {
      Link: page,
      LtId: queryParams.LtId,
      DlId: queryParams.DlId,
    };
  }

  return {
    Link: "",
    LtId: "",
    DlId: "",
  };
};

const getEmailLinkTrackingParams = (queryString: string) => {
  const queryMap = new Map();

  const queryArray = queryString.split("&");
  for (const query of queryArray) {
    const queryKvp = query.split("=");

    const isDlidOrLtid = queryKvp[0] === "ltid" || queryKvp[0] === "dlid";
    queryMap.set(
      queryKvp[0]?.toLowerCase(),
      isDlidOrLtid ? removeUriFragmentFromDlidLtid(queryKvp[1]) : queryKvp[1]
    );
  }

  return {
    LtId: queryMap.get("ltid"),
    DlId: queryMap.get("dlid"),
  };
};

const removeUriFragmentFromDlidLtid = (queryValue?: string) => {
  let queryValueWithoutUriFragment = queryValue;
  if (queryValue?.includes("#")) {
    queryValueWithoutUriFragment = queryValue.slice(0, queryValue.indexOf("#"));
  }

  return queryValueWithoutUriFragment;
};

export default getTrackerData;
