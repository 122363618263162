import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import MenuDropdown from "../MenuDropdown";
import MenuItem from "../MenuItem";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {
  ChatState,
  UserState,
  RootState,
} from "~/common/Store/models/RootReadonly";
import PendingOrders from "./PendingOrders";
import AccountIcon from "../Icons/AccountIcon";
import OrderIcon from "../Icons/OrderIcon";
import ChevronDownIcon from "../Icons/ChevronDownIcon";
import sharedButtonStyles from "../sharedButtonStyles";
import Icons from "~/common/Icons/Standard";

interface Props {
  mounted: boolean;
  chat: ChatState;
  user: UserState;
  checkout: boolean;
}

const LoggedIn = ({ user, checkout }: Props) => {
  const [accountButtonActive, setAccountButtonActive] = useState(false);
  const accountMenuRef = useRef<HTMLUListElement>(null);
  const accountButtonRef = useRef<HTMLButtonElement>(null);

  const closeMenus = () => {
    setAccountButtonActive(false);
  };

  useOnClickOutside(accountMenuRef, accountButtonRef, closeMenus);

  return (
    <ul
      className="hidden items-center m-0 lt:flex lt:ml-2 lg:ml-8 xl:ml-[104px]"
      ref={accountMenuRef}
    >
      <li className={`list-none relative ${checkout ? "mr-4" : ""}`}>
        <button
          type="button"
          ref={accountButtonRef}
          onClick={() => setAccountButtonActive((prev) => !prev)}
          className={`${sharedButtonStyles} ${
            accountButtonActive ? "bg-gray-200" : "bg-transparent"
          }`}
          data-testid="loggedin-user-account-dropdown"
        >
          <AccountIcon
            classNames="pr-1 self-center"
            style={{ minWidth: "12px", width: "12px", height: "16px" }}
          />
          <span className="leading-none">
            <span className="max-w-16 capitalize truncate block text-xs font-normal text-left leading-none">
              {user.firstName}&#39;s
            </span>
            <span className="flex items-center text-sm leading-none">
              Account
              <ChevronDownIcon classNames="pl-1 text-gray-800 fill-current" />
            </span>
          </span>
        </button>
        <MenuDropdown
          active={accountButtonActive}
          menuRef={accountMenuRef}
          isLoggedIn={user.registered}
          isCheckout={checkout}
        >
          <div
            className={`${
              accountButtonActive ? "block" : "hidden"
            } grid grid-cols-2`}
          >
            <div className="flex flex-col px-2-1/2 pt-2 pb-1">
              <div className="flex items-center pl-2 pr-4 py-1-1/2">
                <Icons name="rounded-home" className="w-5 h-4" />
                <p className="ml-2 mb-0 text-sm font-bold leading-4">
                  My Account
                </p>
              </div>
              <MenuItem
                content="Account"
                href="/myaccount/"
                name="my-account"
              />
              <MenuItem
                content="Orders"
                href="/myaccount/orders/"
                name="orders"
              />
              <MenuItem
                content="Auto Reorder"
                href="/myaccount:autoreorder/"
                name="auto-reorder"
              />
              <MenuItem
                content="Returns"
                href="/myaccount/returns/"
                name="returns"
              />
              <MenuItem
                content="Shipping"
                href="/myaccount/shipping/?returnUrl="
                name="shipping"
              />
              <MenuItem
                content="Billing"
                href="/myaccount/payment/"
                name="billing"
              />
              {user.quotes && (
                <MenuItem
                  name="quotes"
                  content="Your Quotes"
                  href="/manage-quotes/"
                />
              )}
              {Boolean(user.pendingOrders) && (
                <MenuItem
                  name="pending-orders"
                  content={<PendingOrders pendingOrders={user.pendingOrders} />}
                  href="/revieworder.html"
                />
              )}
              <MenuItem
                divider
                content="Log Out"
                href="/myaccount/?logout=Y"
                name="log-out"
                extraListClasses="mt-auto"
              />
            </div>
            <div className="px-2-1/2 py-2 bg-gray-50 border-0 border-l border-solid border-gray-100 rounded-r">
              <div className="flex items-center pl-2 pr-4 py-1-1/2">
                <Icons name="multiple-files" className="w-[13px] h-4" />
                <p className="ml-2 mb-0 text-sm font-bold leading-4">Lists</p>
              </div>
              <MenuItem
                name="wish-list"
                content="Wish Lists"
                href="/wishlist.html"
              />
              <MenuItem
                content="Rapid Reorder"
                href="/reorder.html"
                name="rapid-reorder"
              />
              {user.orderGuides && (
                <MenuItem
                  name="order-guides"
                  content="Order Guides"
                  href="/myproducts.html"
                />
              )}
              <div className="pt-4">
                <div className="flex items-center pl-2 pr-4 py-1-1/2">
                  <Icons name="bookmark" className="w-3 h-4" />
                  <p className="ml-2 mb-0 text-sm font-bold leading-4">
                    Quick Links
                  </p>
                </div>
                <MenuItem
                  content="WebstaurantPlus"
                  href={user.hasWebPlus ? "/myaccount/plus/" : "/plus/"}
                  name="webstaurant-plus"
                />
                <MenuItem
                  content="Rewards"
                  href="/rewards/"
                  name="webstaurant-rewards"
                />
                <MenuItem
                  content="Get the App"
                  href="/services/app/"
                  name="webstaurant-app-quick-link"
                />
                <MenuItem
                  content="VersaHub"
                  href="/versahub#/"
                  name="versahub"
                />
              </div>
            </div>
          </div>
        </MenuDropdown>
      </li>
      {!checkout && (
        <li className="list-none pr-4 ml-1 hidden lt:block">
          <a
            href="/myaccount/orders/"
            className={`${sharedButtonStyles}`}
            data-testid="order-btn"
          >
            <OrderIcon
              classNames="pr-1"
              style={{ minWidth: "16px", width: "16px", height: "16px" }}
            />
            <span className="leading-none">
              <span className="block text-xs leading-none font-normal text-left">
                Returns &#38;
              </span>
              <span className="flex items-center text-sm leading-none">
                Orders
              </span>
            </span>
          </a>
        </li>
      )}
    </ul>
  );
};

export const mapStateToProps = (state: RootState, ownProps: Props) => {
  if (!ownProps.mounted) return ownProps;

  return state;
};

export default connect(mapStateToProps)(LoggedIn);
