/* eslint-disable import/prefer-default-export */
import { createElement, ExtendedImgHTMLAttributes } from "react";

type DefaultProps = ExtendedImgHTMLAttributes<
  Omit<HTMLImageElement, "width" | "height">
>;

type ImageProps = DefaultProps & {
  width: `${number}` | number;
  height: `${number}` | number;
};

/**
 * An <img> component with required width and height properties.
 *
 * **Props - Width & Height**
 *
 * The width and height properties tell the browser what size of the image will be once rendered allowing it to reserve the space needed to display the image. See {@link https://web.dev/articles/serve-images-with-correct-dimensions#avoid_layout_shifts_by_specifying_dimensions Googles article} on images for more information.
 */
export function Image(props: ImageProps): JSX.Element {
  return createElement("img", props);
}
