import SHA256 from "crypto-js/sha256";
import Environment from "../../common/Environment";
import type { TrackingPixel } from "../MarketingEvents/MarketingDataDefinitions";
import { logException } from "../../__alias__/error-tracking";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pintrk: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

export default class PinterestTrackingPixel implements TrackingPixel {
  public trackingPixelId = Environment.isProduction
    ? "2612764898441"
    : "2614091255406";

  private email?: string;

  constructor(email: string) {
    if (email && email !== "") {
      this.email = SHA256(email).toString();
    }
  }

  public async load(): Promise<void> {
    try {
      await this.loadPixel();
    } catch (error) {
      await logException(new Error(`Error loading Pinterest Pixel:"${error}"`));
    }

    try {
      await this.initPixel();
    } catch (error) {
      await logException(
        new Error(`Error initializing Pinterest Pixel:"${error}"`)
      );
    }
  }

  private initPixel(): void {
    /* eslint-disable */
    if (this.email) {
      pintrk("load", this.trackingPixelId, { em: this.email });
    } else {
      pintrk("load", this.trackingPixelId);
    }

    pintrk("page");
  }

  private async loadPixel() {
     // @ts-ignore
    !function (e) {
       if (!window.pintrk) {
         window.pintrk = function () {
           window.pintrk.queue.push(Array.prototype.slice.call(arguments))
           }; var n = window.pintrk; n.queue = [], n.version = "3.0";
           var t = document.createElement("script"); t.async = !0, t.src = e;
           var r = document.getElementsByTagName("script")[0];
           // @ts-ignore
           r.parentNode.insertBefore(t, r)
         }
    }("https://s.pinimg.com/ct/core.js");
  }
}