import React from "react";

type Props = {
  href: string;
  children: JSX.Element | JSX.Element[];
};

const MenuButton = ({ href, children }: Props) => {
  return (
    <a
      className="bg-white w-auto h-12 p-0 rounded border border-gray-200 border-solid text-gray-800"
      href={href}
    >
      <div className="flex justify-center items-center space-x-1.5 h-full w-full">
        {children}
      </div>
    </a>
  );
};

export default MenuButton;
