/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { cls } from "cls";
import { UserState } from "~/common/Store/models/RootReadonly";
import PlusPreview from "./Logos/PlusPreview";

interface Props {
  user: UserState;
  checkout: boolean;
}

interface LinkContainerProps {
  showStandardFreeviewAd: boolean;
  checkout: boolean;
  children: JSX.Element[];
}

const RenderLinkContainer = ({
  showStandardFreeviewAd,
  children,
  checkout,
}: LinkContainerProps): JSX.Element => {
  if (checkout) {
    return (
      <div
        className={cls(
          "flex",
          "justify-center",
          "content-center",
          "items-center",
          "gap-2"
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <a
      href={showStandardFreeviewAd ? "/event/shop-with-plus/" : "/plus/"}
      className={cls(
        "flex",
        "justify-center",
        "content-center",
        "items-center",
        "gap-2"
      )}
    >
      {children}
    </a>
  );
};

const PlusPreviewBanner = ({ user, checkout }: Props) => {
  const formattedDate = user.freeviewEndDate
    ? new Date(user.freeviewEndDate).toLocaleDateString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      })
    : "";

  const showStandardFreeviewAd =
    user.freeviewPromotion === 1 || user.freeviewPromotion === 3;
  const showEndingFreeviewAd =
    user.freeviewPromotion === 2 || user.freeviewPromotion === 4;

  return (
    <div
      gtm-id="plus-freeview-global-header"
      data-testid="alert-banner"
      className={cls(
        "bg-blue-900",
        "min-h-[32px]",
        "flex",
        "justify-center",
        "content-center",
        "items-center"
      )}
    >
      <RenderLinkContainer
        showStandardFreeviewAd={showStandardFreeviewAd}
        checkout={checkout}
      >
        <PlusPreview classNames="h-[17px] w-[79px]" />
        <div>
          {showStandardFreeviewAd && (
            <div
              className={cls(
                "sm:text-sm",
                "text-xs",
                "content-center",
                "font-proxima",
                "font-semibold",
                "text-white"
              )}
            >
              FREE Shipping Unlocked!
              <span className="hidden lt:inline">
                &nbsp;Take advantage of all the benefits of Plus.
              </span>
              &nbsp;| Ends&nbsp;{formattedDate}
            </div>
          )}
          {showEndingFreeviewAd && (
            <div
              className={cls(
                "sm:text-sm",
                "text-xs",
                "content-center",
                "font-proxima",
                "font-semibold",
                "text-white"
              )}
            >
              Don’t Lose Free Shipping! |
              <span className="lt:inline hidden">&nbsp;Trial</span> Ends&nbsp;
              {formattedDate}
            </div>
          )}
        </div>
        {!checkout ? (
          <div
            className={cls(
              "sm:text-sm",
              "text-xs",
              "content-center",
              "font-proxima",
              "font-bold"
            )}
          >
            <div className="lt:inline hidden text-[#29C5FF] hover:underline">
              {showStandardFreeviewAd ? "Shop Now" : "Sign Up Now"}
            </div>
            <div className="lt:hidden inline text-white">{">"}</div>
          </div>
        ) : (
          <></>
        )}
      </RenderLinkContainer>
    </div>
  );
};

export default PlusPreviewBanner;
