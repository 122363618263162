import React from "react";

const ReturnsAndOrdersSvg = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M16 4.55556L8.5 1L1 4.55556M16 4.55556L8.5 8.11111M16 4.55556V13.4444L8.5 17M8.5 8.11111L1 4.55556M8.5 8.11111V17M1 4.55556V13.4444L8.5 17"
      stroke="#3b4238"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default ReturnsAndOrdersSvg;
