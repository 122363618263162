import React from "react";

interface ChevronDownIconProps {
  classNames?: string;
}

const ChevronDownIcon = ({ classNames = "" }: ChevronDownIconProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.99999 3.78145L8.29999 0.481445L9.24266 1.42411L4.99999 5.66678L0.757324 1.42411L1.69999 0.481445L4.99999 3.78145Z" />
    </svg>
  );
};

export default ChevronDownIcon;
