import React from "react";
import { cls } from "cls";
import { connect } from "react-redux";
import { ChatState, RootState } from "~/common/Store/models/RootReadonly";
import HelpCenter from "./HelpCenter";
import CustomMessage from "./CustomMessage";
import PhoneApp from "./PhoneApp";

interface AlertBannerLeftContainerProps {
  chat: ChatState;
  eventDayBannerVisible: boolean;
  mounted: boolean;
  isCustomerServiceMessageAvailable: boolean;
  isEventDayDataLoading: boolean;
  checkout: boolean;
  isPlusEssentials?: boolean;
  pageCreatorTextColor?: string;
}

const AlertBannerLeftContainer = ({
  chat,
  eventDayBannerVisible,
  isCustomerServiceMessageAvailable,
  isEventDayDataLoading,
  checkout,
  isPlusEssentials,
  pageCreatorTextColor,
}: AlertBannerLeftContainerProps) => {
  const showAppAd =
    !isCustomerServiceMessageAvailable && !eventDayBannerVisible && !checkout;

  return (
    <div
      data-testid="alert-banner-left-container"
      className={cls(
        "items-center",
        "space-x-6",
        checkout ? "lt:h-5" : "",
        "hidden",
        "lt:flex",
        "flex-initial"
      )}
    >
      {!isEventDayDataLoading && (
        <HelpCenter
          isPlusEssentials={isPlusEssentials}
          pageCreatorTextColor={pageCreatorTextColor}
        />
      )}
      {isCustomerServiceMessageAvailable && !isEventDayDataLoading && (
        <CustomMessage
          {...{
            ...chat.headerBanner,
            eventDayBannerVisible,
            isPlusEssentials,
            pageCreatorTextColor,
          }}
        />
      )}
      {showAppAd && <PhoneApp isPlusEssentials={isPlusEssentials} />}
    </div>
  );
};

export const mapStateToProps = (
  state: RootState,
  ownProps: AlertBannerLeftContainerProps
) => {
  if (!ownProps.mounted) {
    return {};
  }

  return state;
};

export default connect(mapStateToProps)(AlertBannerLeftContainer);
