/**
 * This module was automatically generated by `ts-interface-builder`
 */
import * as t from "ts-interface-checker";
// tslint:disable:object-literal-key-quotes

export const Hours = t.iface([], {
  "weekday": t.opt("string"),
  "daily": t.array("string"),
  "weekend": t.opt("string"),
});

export const HeaderBanner = t.iface([], {
  "iconClassToUse": "string",
  "message": "string",
});

export const Chat = t.iface([], {
  "target": t.opt("string"),
  "hours": "Hours",
  "link": t.opt("string"),
  "text": t.opt("string"),
  "class": t.opt("string"),
  "statusText": t.opt("string"),
  "headerBanner": t.opt("HeaderBanner"),
});

const exportedTypeSuite: t.ITypeSuite = {
  Hours,
  HeaderBanner,
  Chat,
};
export default exportedTypeSuite;
