import React from "react";
import PlusBadge from "../Logos/PlusBadge";
import Chevron from "../icons/Chevron";

const PlusEssentialsAd = () => {
  return (
    <a
      id="global-plus-essentials-3"
      data-testid="global-plus-essentials-ad-3"
      href="/event/get-50-off/"
      className="global-plus-essentials"
    >
      <div className="text-white text-xs flex justify-center items-center h-[17px] whitespace-pre">
        <PlusBadge classNames="pr-2" />
        <span>
          You have $50 FREE towards{" "}
          <span className="hidden xsl:inline">everyday </span>essentials |{" "}
        </span>
        <span className="font-bold text-[#29C5FF] hover:underline">
          Use Code: PLUS50
        </span>
        <Chevron strokeColor="#29C5FF" classNames="pl-2" />
      </div>
    </a>
  );
};

export default PlusEssentialsAd;
