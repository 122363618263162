import React, { FC, PropsWithChildren } from "react";

type ChildOrNullProps = PropsWithChildren<{
  condition: boolean;
}>;

const ChildOrNull: FC<ChildOrNullProps> = ({ condition, children }) =>
  condition ? <>{children}</> : null;

export default ChildOrNull;
