import React from "react";

const CartIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M3.00012 4.81013L0.567871 2.37863L1.62912 1.31738L4.06062 3.74963H15.4921C15.609 3.74962 15.7243 3.77694 15.8288 3.82941C15.9332 3.88187 16.024 3.95803 16.0938 4.0518C16.1636 4.14558 16.2105 4.25437 16.2308 4.3695C16.2511 4.48463 16.2442 4.6029 16.2106 4.71488L14.4106 10.7149C14.3643 10.8694 14.2694 11.005 14.14 11.1013C14.0105 11.1976 13.8535 11.2496 13.6921 11.2496H4.50012V12.7496H12.7501V14.2496H3.75012C3.55121 14.2496 3.36044 14.1706 3.21979 14.03C3.07914 13.8893 3.00012 13.6985 3.00012 13.4996V4.81013ZM4.50012 5.24963V9.74963H13.1341L14.4841 5.24963H4.50012ZM4.12512 17.2496C3.82675 17.2496 3.5406 17.1311 3.32963 16.9201C3.11865 16.7091 3.00012 16.423 3.00012 16.1246C3.00012 15.8263 3.11865 15.5401 3.32963 15.3291C3.5406 15.1182 3.82675 14.9996 4.12512 14.9996C4.42349 14.9996 4.70964 15.1182 4.92062 15.3291C5.13159 15.5401 5.25012 15.8263 5.25012 16.1246C5.25012 16.423 5.13159 16.7091 4.92062 16.9201C4.70964 17.1311 4.42349 17.2496 4.12512 17.2496ZM13.1251 17.2496C12.8268 17.2496 12.5406 17.1311 12.3296 16.9201C12.1186 16.7091 12.0001 16.423 12.0001 16.1246C12.0001 15.8263 12.1186 15.5401 12.3296 15.3291C12.5406 15.1182 12.8268 14.9996 13.1251 14.9996C13.4235 14.9996 13.7096 15.1182 13.9206 15.3291C14.1316 15.5401 14.2501 15.8263 14.2501 16.1246C14.2501 16.423 14.1316 16.7091 13.9206 16.9201C13.7096 17.1311 13.4235 17.2496 13.1251 17.2496Z"
        fill="white"
      />
    </svg>
  );
};

export default CartIcon;
