import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";
import * as actions from "../../actions/loginModal";
import { LoginModalState } from "../../models/RootReadonly";

export const defaultLoginModalObject: LoginModalState = {
  open: false,
};

export default (state = defaultLoginModalObject, action: RootAction) => {
  switch (action.type) {
    case getType(actions.setLoginModalOpen):
      return {
        ...state,
        open: action.payload,
      };
    default:
      return {
        ...state,
        open: false,
      };
  }
};
