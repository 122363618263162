// eslint-disable-next-line import/no-unresolved
import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";

import * as actions from "../../actions/user";
import platinumNotificationsReducer from "./platinumNotificationsReducer";
import platinumRepContactReducer from "./platinumRepContactReducer";
import { UserState } from "../../models/RootReadonly";

export const defaultUserObject: UserState = {
  platinum: {
    enabled: false,
    notifications: [],
    repInfo: {
      images: [],
    },
  },
  country: {},
  wishLists: [],
  autoReorders: {},
  analyticsData: {},
  facebookUserData: {},
  samplePurchaseHistory: [],
  freeviewPromotion: 0,
  gpcEnabled: false,
};

export const versaHubEligibleUser: UserState = {
  ...defaultUserObject,
  isVersaHubEligible: true,
};

export default (state = defaultUserObject, action: RootAction) => {
  switch (action.type) {
    case getType(actions.updateUser):
      return {
        ...action.payload,
        platinum: {
          ...action.payload.platinum,
          notifications: platinumNotificationsReducer(
            state.platinum.notifications,
            action
          ),
          repInfo: platinumRepContactReducer(
            action.payload.platinum.repInfo,
            action
          ),
        },
      };
    case getType(actions.incrementItemsInCart):
      return {
        ...state,
        itemsInCart: (state.itemsInCart || 0) + action.payload,
      };
    case getType(actions.setItemsInCart):
      return {
        ...state,
        itemsInCart: action.payload,
      };
    default:
      return {
        ...state,
        platinum: {
          ...state.platinum,
          notifications: platinumNotificationsReducer(
            state.platinum.notifications,
            action
          ),
          repInfo: platinumRepContactReducer(state.platinum.repInfo, action),
        },
      };
  }
};
