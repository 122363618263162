import { createStandardAction } from "typesafe-actions";
import { User } from "../../models/User";

export const updateUser = createStandardAction("UPDATE_USER")<User>();

export const incrementItemsInCart = createStandardAction(
  "INCREMENT_ITEMS_IN_CART"
)<number>();

export const setItemsInCart = createStandardAction(
  "SET_ITEMS_IN_CART"
)<number>();
