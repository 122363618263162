import React from "react";
import ct from "class-types.macro";
import Question from "./icons/Question";
import Chevron from "./icons/Chevron";

interface HelpCenterProps {
  isPlusEssentials?: boolean;
  pageCreatorTextColor?: string;
}

const HelpCenter = ({
  isPlusEssentials,
  pageCreatorTextColor = "",
}: HelpCenterProps) => {
  const changeStylesDependingOnEvent = (
    plusEssentialColor: string,
    alertBannerDefaultColor: string
  ) => {
    if (
      pageCreatorTextColor &&
      pageCreatorTextColor.length > 3 &&
      !isPlusEssentials
    ) {
      return pageCreatorTextColor;
    }
    if (isPlusEssentials) {
      return plusEssentialColor;
    }
    return alertBannerDefaultColor;
  };

  return (
    <div className={ct("flex", "items-center")}>
      <a
        href="/ask.html"
        className={`${ct(
          "inline-flex",
          "items-center",
          "font-semibold",
          "text-xs",
          isPlusEssentials ? "text-white" : "text-gray-800",
          "hover:underline"
        )} tracking-[.02em]`}
        data-testid="help-center-banner"
        style={
          pageCreatorTextColor && !isPlusEssentials
            ? { color: pageCreatorTextColor }
            : {}
        }
      >
        <Question
          classNames={ct("pr-2")}
          fillColor={changeStylesDependingOnEvent("#00AEEF", "#1175D0")}
        />
        Help Center
        <Chevron
          classNames={ct("pl-2")}
          strokeColor={changeStylesDependingOnEvent("#FFFFFF", "#3b4238")}
        />
      </a>
    </div>
  );
};

export default HelpCenter;
