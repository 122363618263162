import { connect } from "react-redux";
import {
  defaultUserObject,
  defaultChatObject,
} from "~/common/Store/rootReducer";
import { RootState } from "~/common/Store/models/RootReadonly";
import { User } from "~/common/Store/models/User";
import { Chat } from "~/common/Store/models/Chat";

type Props = {
  /**
   * Should be true when the component is mounted in the browser.
   * We have a hook html/template/site2012/lib/js/common/useIsMounted.ts
   * that can create this prop for you.
   */
  mounted: boolean;
  user?: User;
  chat?: Chat;
};

export const oldMapStateToProps = (state: RootState, ownProps: Props) => {
  if (!ownProps.mounted) return ownProps;
  /** Don't overwrite chat props until we have new ones from state! */
  if (!state.chat.hours?.weekend) return { ...state, chat: ownProps.chat };
  return state;
};

function mapStateToProps(
  storeState: RootState,
  { mounted, user, chat }: Props
) {
  if (!mounted) {
    return { user: user || defaultUserObject, chat: chat || defaultChatObject };
  }

  return { user: storeState.user, chat: storeState.chat };
}

/**
 * Connects to our redux store and provides a valid user object.
 */
export default connect(mapStateToProps);

export type { User, Chat };
