import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        d="M0.317295 0.338455C0.758053 -0.131695 1.49368 -0.109276 1.96036 0.38853L7.5 6.29759L13.0396 0.38853C13.5063 -0.109276 14.2419 -0.131695 14.6827 0.338455C15.1235 0.808605 15.1024 1.59329 14.6358 2.0911L9.09612 8.00015L14.6355 13.9089C15.1022 14.4067 15.1232 15.1914 14.6824 15.6615C14.2417 16.1317 13.506 16.1093 13.0393 15.6115L7.5 9.70272L1.96065 15.6115C1.49397 16.1093 0.758342 16.1317 0.317584 15.6615C-0.123173 15.1914 -0.102156 14.4067 0.364529 13.9089L5.90388 8.00015L0.36424 2.0911C-0.102444 1.59329 -0.123462 0.808605 0.317295 0.338455Z"
        fill="white"
      />
    </svg>
  );
};

export default CloseIcon;
