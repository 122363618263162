import React, { useRef } from "react";
import { connect } from "react-redux";
import { cls } from "cls";
import MenuButton from "./MenuButton";
import CartButton from "./CartButton";
import NotLoggedIn from "./NotLoggedIn";
import Search from "./Search";
import {
  RootState,
  ChatState,
  UserState,
} from "~/common/Store/models/RootReadonly";
import LoggedIn from "./LoggedIn";
import Logos from "./Logos";

interface Props {
  chat: ChatState;
  checkout: boolean;
  user: UserState;
  mounted: boolean;
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileNavOpen: boolean;
  productCount: number;
  imageSearchFeatureFlag?: boolean;
}

const Banner = ({
  chat,
  checkout,
  user,
  setMobileNavOpen,
  mobileNavOpen,
  mounted,
  imageSearchFeatureFlag,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      role="banner"
      className={cls(
        "bg-origin-box-border",
        "bg-green-500",
        "py-3",
        "md:py-4",
        "lt:bg-gray-100",
        "lt:py-[22px]",
        "lz:py-[18px]"
      )}
      data-testid="banner"
    >
      <div className={cls("container", "flex", "flex-col", "md:mx-auto")}>
        <div
          className={cls(
            "flex",
            "flex-nowrap",
            "items-center",
            "relative",
            "h-7",
            "md:h-10",
            checkout ? "justify-end" : "pb-3.5 lt:pb-0 justify-between"
          )}
        >
          {!checkout && (
            <div className={cls("flex", "flex-nowrap", "lt:hidden")}>
              <MenuButton
                setMobileNavOpen={setMobileNavOpen}
                mobileNavOpen={mobileNavOpen}
              />
            </div>
          )}
          <div
            className={cls(
              "flex",
              "items-center",
              "flex-initial",
              "lt:flex-none",
              "absolute",
              "lt:relative",
              "lt:left-0",
              "lt:pl-0",
              "lt:pr-8",
              "xxxl:pr-[52px]",
              "h-[30px]",
              "md:h-[40px]",
              "lz:h-[52px]",
              "lt:min-w-[221px]",
              "lz:min-w-[288px]",
              "xl:min-w-[336px]",
              checkout
                ? "left-0 mr:0 pr-4 xxxs:pr-0"
                : "left-1/2 -translate-x-1/2 lt:translate-x-0 px-4 mr-0 xxs:px-0"
            )}
          >
            <Logos user={user} checkout={checkout} />
          </div>
          <div
            className={cls(
              "hidden",
              "flex-1",
              "ml-0",
              "lt:flex",
              "max-w-[900px]"
            )}
          >
            {!checkout && (
              <Search imageSearchFeatureFlag={imageSearchFeatureFlag} />
            )}
          </div>
          <div
            className={cls(
              "flex",
              "items-center",
              "justify-end",
              "xl:min-w-[360px]"
            )}
          >
            {user.registered ? (
              <LoggedIn
                chat={chat}
                user={user}
                mounted={mounted}
                checkout={checkout}
              />
            ) : (
              <NotLoggedIn checkout={checkout} />
            )}
            <CartButton itemsInCart={user.itemsInCart} />
          </div>
        </div>
        <div className={cls("lt:hidden")}>
          {!checkout && (
            <Search imageSearchFeatureFlag={imageSearchFeatureFlag} />
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState, ownProps: Props) => {
  if (!ownProps.mounted) return {};

  return state;
};

export default connect(mapStateToProps)(Banner);
