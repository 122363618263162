import React, { useRef, useState } from "react";
import MenuDropdown from "../MenuDropdown";
import MenuItem from "../MenuItem";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import AccountIcon from "../Icons/AccountIcon";
import OrderIcon from "../Icons/OrderIcon";
import ChevronDownIcon from "../Icons/ChevronDownIcon";
import sharedButtonStyles from "../sharedButtonStyles";

interface NotLoggedInProps {
  checkout: boolean;
}

const NotLoggedIn = ({ checkout }: NotLoggedInProps) => {
  const [accountButtonActive, setAccountButtonActive] = useState(false);
  const accountMenuRef = useRef<HTMLUListElement>(null);
  const accountButtonRef = useRef<HTMLButtonElement>(null);

  const closeMenus = () => {
    setAccountButtonActive(false);
  };

  useOnClickOutside(accountMenuRef, accountButtonRef, closeMenus);

  return (
    <ul className="hidden items-center m-0 lt:flex lt:ml-2 lg:ml-8 xl:ml-[104px]">
      <li className={`list-none relative ${checkout ? "pr-4" : ""}`}>
        <button
          type="button"
          ref={accountButtonRef}
          onClick={() => setAccountButtonActive((prev) => !prev)}
          className={`${sharedButtonStyles} ${
            accountButtonActive ? "bg-gray-200" : "bg-transparent"
          }`}
          data-testid="account-dropdown"
          aria-expanded={accountButtonActive}
        >
          <AccountIcon
            classNames="pr-1 self-center"
            style={{ minWidth: "12px", width: "12px", height: "16px" }}
          />
          <span className="leading-none">
            <span className="block text-xs leading-none font-normal text-left">
              Sign In
            </span>
            <span className="flex items-center text-sm leading-none">
              Account
              <ChevronDownIcon classNames="pl-1 text-gray-800 fill-current" />
            </span>
          </span>
        </button>
        <MenuDropdown active={accountButtonActive} menuRef={accountMenuRef}>
          <MenuItem
            name="global-header-sign-in-dropdown"
            content="Sign In"
            href="/myaccount/"
          />
          <MenuItem
            content="Create An Account"
            href="/myaccount.html?goto=register"
          />
        </MenuDropdown>
      </li>
      {!checkout && (
        <li className="list-none pr-3.5 hidden lt:block ml-0 text-sm">
          <a
            href="/myaccount/orders/"
            className={`${sharedButtonStyles}`}
            data-testid="order-btn"
          >
            <OrderIcon
              classNames="pr-1"
              style={{ minWidth: "16px", width: "16px", height: "16px" }}
            />
            <span className="leading-none">
              <span className="block text-xs leading-none font-normal text-left">
                Returns &#38;
              </span>
              <span className="flex items-center text-sm leading-none">
                Orders
              </span>
            </span>
          </a>
        </li>
      )}
    </ul>
  );
};

export default NotLoggedIn;
