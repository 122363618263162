import React from "react";

interface PlusBadgeProps {
  classNames?: ClassTypes;
}

const PlusBadge = ({ classNames }: PlusBadgeProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      width="34"
      height="17"
      viewBox="0 0 34 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2615_12158)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7442 15.9647H0.498047L3.10494 4.3957C3.58629 2.25957 5.51056 0.738892 7.73275 0.738892H33.9793L31.372 12.3079C30.8907 14.4444 28.9668 15.9647 26.7442 15.9647Z"
          fill="#00AEEF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4533 7.24114C23.5492 6.06948 24.804 5.10034 26.3337 5.10034C27.5506 5.10034 28.2044 5.72477 28.2044 5.72477C28.2044 5.72477 28.1001 5.95207 27.8335 6.2325C27.5666 6.51294 27.3241 6.65011 27.3241 6.65011C27.3241 6.65011 26.8267 6.35136 26.2116 6.30575C25.2872 6.23645 24.7694 6.80882 25.1161 7.2196C25.5643 7.75103 27.808 7.52158 27.575 9.41893C27.4218 10.6681 26.2105 11.5421 24.6301 11.5421C23.4132 11.5421 22.3586 10.7066 22.3586 10.7066C22.3586 10.7066 22.5275 10.3981 22.8228 10.0843C23.1178 9.77046 23.3512 9.65161 23.3512 9.65161C23.3512 9.65161 24.1797 10.1001 24.7949 10.1461C25.9669 10.2337 26.3016 9.61247 25.9862 9.28571C25.2784 8.55212 23.3399 8.62717 23.4533 7.24114Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6535 3.88513C15.2903 3.62911 14.7947 3.69733 14.7947 3.69733C14.7947 3.69733 13.3044 10.3172 13.2559 10.5316C13.1848 10.8465 13.3697 11.2235 13.5983 11.3851C13.9615 11.6411 14.4574 11.5729 14.4574 11.5729C14.4574 11.5729 15.9525 4.93147 15.9959 4.73865C16.067 4.42374 15.8821 4.04635 15.6535 3.88513Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8694 5.13116C21.4792 5.13116 21.1247 5.24894 21.1247 5.24894L20.1854 9.42138C18.8639 10.7388 17.561 10.3672 17.8345 9.15316L18.7133 5.24894C18.7133 5.24894 18.4125 5.13116 18.0216 5.13116C17.6314 5.13116 17.2769 5.24894 17.2769 5.24894L16.3981 9.15316C16.0506 10.6965 16.7168 11.5809 17.8659 11.5809C18.4967 11.5809 19.2391 11.3137 19.9538 10.7543C20.1562 11.69 21.1353 11.5809 21.1353 11.5809L22.5607 5.24894C22.5607 5.24894 22.2599 5.13116 21.8694 5.13116Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.23465 10.2905C8.57572 10.2905 8.11626 9.90877 7.92591 9.37519L8.38647 7.32846C8.81712 6.79524 9.4487 6.41354 10.1076 6.41354C11.0047 6.41354 11.4237 7.23403 11.1717 8.35218C10.9204 9.46998 10.1317 10.2905 9.23465 10.2905ZM10.6882 5.13129C9.94608 5.13129 9.26309 5.43542 8.7008 5.9331C8.48237 5.2189 7.38149 5.41531 7.38149 5.41531L5.61475 13.2618C6.40786 13.2618 7.19333 12.6291 7.36909 11.8481L7.61158 10.7705C7.95034 11.2686 8.49623 11.5727 9.23829 11.5727C10.8614 11.5727 12.208 10.1278 12.608 8.35218C13.0081 6.5762 12.3116 5.13129 10.6882 5.13129Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2615_12158">
          <rect
            width="34"
            height="16"
            fill="white"
            transform="translate(0 0.231689)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusBadge;
