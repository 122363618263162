import { createStandardAction } from "typesafe-actions";
import { AbRequest } from "../../models/AbFeature";
import { UserAbFeatureOption } from "~/common/AbFeature";

export const addToAbFeatureRequest = createStandardAction(
  "ADD_TO_AB_FEATURE_REQUEST"
)<AbRequest>();

export const updateUserAbFeatureOptions = createStandardAction(
  "UPDATE_USER_AB_FEATURE_OPTIONS"
)<UserAbFeatureOption[]>();
