import type { Tag } from "../../MarketingEvents/MarketingDataDefinitions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pintrk: any;
export default class PinterestTagPageVisitEvent implements Tag {
  eventName = "pagevisit";

  eventId = "";

  sendEvent(): void {
    if (typeof pintrk === "function") {
      pintrk("track", this.eventName, { event_id: this.eventId });
    }
  }
}
