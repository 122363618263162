import React from "react";
import HelpSvg from "./HelpSvg";
import MenuButton from "./MenuButton";
import ReturnsAndOrdersSvg from "./ReturnsAndOrdersSvg";

type Props = {
  isLoggedIn?: boolean;
};

const MenuButtons = ({ isLoggedIn = false }: Props) => (
  <div className="grid grid-cols-1 xxxs:grid-cols-2 gap-4 pb-6">
    {!isLoggedIn && (
      <>
        <MenuButton href="/myaccount/">
          <span className="text-base font-semibold">Sign In</span>
        </MenuButton>
        <MenuButton href="/myaccount.cfm?goto=register">
          <span className="text-base font-semibold">Create Account</span>
        </MenuButton>
      </>
    )}
    <MenuButton href="/myaccount/orders/">
      <ReturnsAndOrdersSvg />
      <span className="text-base font-semibold">Returns &amp; Orders</span>
    </MenuButton>
    <MenuButton href="/ask.html">
      <HelpSvg />
      <span className="text-base font-semibold">Help Center</span>
    </MenuButton>
  </div>
);

export default MenuButtons;
