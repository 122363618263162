import Store from "./common/Store";

const setWindowTokenForAjaxService = () => {
  const { token } = Store.getState().user;
  window.token = token;
  window.Token = token;
};

const ajaxToken = () => {
  setWindowTokenForAjaxService();
  Store.subscribe(setWindowTokenForAjaxService);
};

export default ajaxToken;
