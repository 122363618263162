import { useEffect, useState } from "react";
import { User } from "~/common/Store/models/User";

/** Will return a boolean based on if the session was created via a "login_as_user" url.
 * @param {User} user - The user object from redux
 */
export default function useLoggedInAs(user: User): boolean {
  const [showLoggedInAsBanner, setShowLoggedInAsBanner] = useState<boolean>(
    false
  );

  useEffect(
    function initShowLoggedInAsBanner() {
      const hasCookie = document.cookie.includes("LOGGED_IN_AS_USER=true");
      setShowLoggedInAsBanner(!!user.registered && hasCookie);
    },
    [user]
  );

  return showLoggedInAsBanner;
}
