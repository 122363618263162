import inputTypeDateSupported from "./inputTypeDateSupported";

export default () =>
  !Array.prototype.flat ||
  !window.IntersectionObserver ||
  !window.URLSearchParams ||
  !HTMLCanvasElement.prototype.toBlob ||
  !Element.prototype.prepend ||
  !Element.prototype.append ||
  !FormData.prototype.delete ||
  !Array.prototype.flatMap ||
  !Object.fromEntries ||
  !inputTypeDateSupported() ||
  !String.prototype.trimRight ||
  !String.prototype.matchAll ||
  !String.prototype.replaceAll;
