import React from "react";

interface OrderIconProps {
  classNames?: string;
  style?: React.CSSProperties;
}

const OrderIcon = ({ classNames = "", style = {} }: OrderIconProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      style={style}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.68846 0.0790413C7.88563 -0.0090945 8.11437 -0.0090945 8.31155 0.0790413L15.5843 3.32983C15.8382 3.44332 16 3.68453 16 3.94945V12.0764C16 12.3413 15.8382 12.5826 15.5843 12.696L8.31155 15.9468C8.11437 16.035 7.88563 16.035 7.68846 15.9468L0.415728 12.696C0.161844 12.5826 0 12.3413 0 12.0764V3.94945C0 3.68453 0.161844 3.44332 0.415728 3.32983L7.68846 0.0790413ZM1.45455 5.03339L7.27273 7.63403V14.2433L1.45455 11.6426V5.03339ZM8.72727 14.2433L14.5455 11.6426V5.03339L8.72727 7.63403V14.2433ZM8 6.44137L13.575 3.94945L8 1.45752L2.42502 3.94945L8 6.44137Z"
        fill="#3b4238"
      />
    </svg>
  );
};

export default OrderIcon;
