import "element-closest";
import { storageFactory } from "storage-factory";
import PinterestTrackingPixel from "./analytics/TrackingPixels/PinterestTrackingPixel";
import PinterestApiEvent from "./analytics/MarketingEvents/Pinterest/PinterestApiEvent";
import PinterestApiCommerceEvent from "./analytics/MarketingEvents/Pinterest/PinterestApiCommerceEvent";
import Store from "./common/Store";
import { updateUser } from "./common/Store/actions/user";
import {
  createProducts,
  trackYmanCarouselViews,
  bindYmanClickEvent,
  trackRPFYCarouselViews,
  sendFacebookPixelServerEvent,
  getFacebookPixelId,
  getFacebookPixelUserData,
  getIsInternalUserStatus,
  getRandomNumberString,
  sendHashedCustomerEmailSignupEvent,
  updateUserWithNewCartTotal,
  CheckForUserDataChangesAndUpdate,
  getUserForPinterestEvent,
  bindTrackingForElements,
  getDataForProductsInRow,
  trackCarouselImpression,
  bindAddToCartButtonClicksForCarouselTracking,
} from "./userAnalytics";
import {
  sendBingViewCartEvent,
  sendBingProductPageViewEvent,
} from "./bingAnalytics";
import { sendGtmEvent } from "./analytics/definitions";
import { addPriceSpiderTokenToSessionStorage } from "~/FullCheckout/CheckoutComplete/OrderConfirmation/Analytics/PriceSpiderAnalytics";
import { getDynamicData } from "./common/Store/actions/updateUserAndChatFromOrigin";
import PinterestTagPageVisitEvent from "./analytics/TrackingPixels/TagEvents/PinterestTagPageVisitEvent";
import PinterestTagAddToCartEvent from "./analytics/TrackingPixels/TagEvents/PinterestTagAddToCartEvent";
import MarketingEventHelpers from "./analytics/MarketingHelpers/MarketingEventHelpers";

const session = storageFactory(() => window.sessionStorage);
window.dataLayer = window.dataLayer || [];

/* global fbq, gacData, XMLHttpRequest */
const promoCount = {};

const createListingProduct = async (productElement) => {
  const itemNumber = productElement.getAttribute("data-itemnumber");
  const products = await createProducts([{ itemNumber }]);

  if (products[0]) {
    products[0].position = productElement.getAttribute("data-position");
  }
  return products;
};

function createPromotion(promoElement) {
  const promo = {};
  promo.id = promoElement.getAttribute("data-id");
  promo.name = promoElement.getAttribute("data-name");
  promo.position = promoElement.getAttribute("data-position");
  promo.isBanner =
    Boolean(promoElement.getAttribute("isbanner")) ||
    Boolean(promoElement.getAttribute("data-isbanner"));
  return promo;
}

function sendPromotionClickEvent(promotions) {
  window.dataLayer.push({
    event: "promotionClick",
    ecommerce: {
      promoClick: {
        promotions,
      },
    },
  });
}

const sendPromotionViewEvent = (promotions) => {
  window.dataLayer.push({
    event: "promotionView",
    ecommerce: {
      promoView: {
        promotions,
      },
    },
  });
};

export const sendAddToCartEvent = async (products) => {
  const quantity = products.reduce(
    (currentSum, product) => currentSum + parseInt(product.quantity, 10),
    0
  );

  const isValidCartQuantity =
    quantity && Number.isInteger(quantity) && quantity <= 30000;

  if (isValidCartQuantity) {
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currencyCode: "USD",
        add: {
          products,
        },
      },
    });
  }

  const priceTotal = products.reduce(
    (prev, current) => prev + parseFloat(current.price),
    0.0
  );

  updateUserWithNewCartTotal(true, priceTotal);
};

function sendProductPageViewEvent(products) {
  window.dataLayer.push({
    event: "productPageView",
    ecommerce: {
      currencyCode: "USD",
      detail: {
        products,
      },
    },
  });
}

function promotionClick(promoElement) {
  const promo = createPromotion(promoElement);
  sendPromotionClickEvent([promo]);
}

const promotionView = (promoElement) => {
  const promo = createPromotion(promoElement);
  if (promoCount[promo.id] === undefined) {
    sendPromotionViewEvent([promo]);
    promoCount[promo.id] = 1;
  }
};

const sendAddToCartEvents = (products) => {
  const feedIdentifiers = [];
  let productValueTotal = 0;

  products.forEach((element) => {
    feedIdentifiers.push(element.id);
    productValueTotal += element.price;
  });

  sendAddToCartEvent(products);
  sendFacebookPixelAddToCartEvent(feedIdentifiers, productValueTotal);
  new PinterestApiCommerceEvent(
    "add_to_cart",
    new PinterestTagAddToCartEvent(products),
    products
  ).sendEvent();
};

// eslint-disable-next-line import/prefer-default-export
export const createCartProductAndSendAddToCartEvents = async (
  itemNumber,
  quantity
) => {
  const products = await createProducts([{ itemNumber, quantity }]);
  sendAddToCartEvents(products);
};

export const productPageViewV2 = async (itemNumber) => {
  const products = await createProducts([{ itemNumber }]);

  if (products[0]) {
    sendProductPageViewEvent(products);
    sendBingProductPageViewEvent(products[0].id);
    sendFacebookPixelViewContentEvent(products[0].id);
  }
};

const multipleATCEvent = async (productElements) => {
  const products = [];

  productElements.forEach((item) => {
    const product = { itemNumber: item.getAttribute("data-item-number") };
    products.push(product);
  });

  const productData = await createProducts(products);

  sendAddToCartEvents(productData);
};

function getCheckoutFunnelType(pageType) {
  let isLoggedIn = false;
  let isLiftgate = false;
  let isLeasing = false;
  let funnelType = "";
  const formattedPageType = pageType || "";
  const { index } = Store.getState().user;

  isLoggedIn = index > 0;

  const commonCarrier = document.getElementById("common_carrier");
  if (
    commonCarrier !== null ||
    formattedPageType === "/liftgatepolicycart.cfm"
  ) {
    isLiftgate = true;
  }

  const leasing = document.getElementById("isLeasing");
  if (leasing !== null) {
    isLeasing = leasing.value === "true";
  }

  funnelType =
    (isLoggedIn ? "Logged In" : "Guest") +
    (isLiftgate ? " With LiftGate" : "") +
    (isLeasing ? " With Leasing" : "");

  return funnelType;
}

const sendBeginCheckoutAndViewCartEvent = (
  products,
  totalValue,
  couponCodes
) => {
  let coupons = "";
  for (let i = 0; i < couponCodes.length; i += 1) {
    coupons += couponCodes[i].getAttribute("data-discount-code");
    if (i !== couponCodes.length - 1) {
      coupons += ",";
    }
  }
  window.dataLayer.push({
    event: "begin_checkout_and_view_cart",
    ecommerce: {
      items: products,
      value: totalValue,
      coupon: coupons,
    },
  });
};

const viewCartPageView = async () => {
  const funnelType = getCheckoutFunnelType();
  const products = [];
  const productsFeedIds = [];
  const productBlocks = document.querySelectorAll("div.gtm-product-auto");
  const couponCodes = document.querySelectorAll("a[data-discount-code]");
  let totalValue = 0;

  for (let i = 0; i < productBlocks.length; i += 1) {
    /* eslint-disable-next-line no-await-in-loop */
    const product = await createListingProduct(productBlocks[i]);
    if (product[0]) {
      totalValue += product[0].price;
      products.push(product[0]);
      productsFeedIds.push(product[0].id);
    }
  }

  const { user } = Store.getState();
  user.cartTotal = totalValue;
  updateUser(user);

  sendBingViewCartEvent(productsFeedIds);
  sendBeginCheckoutAndViewCartEvent(products, totalValue, couponCodes);

  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
          option: funnelType,
        },
        products,
      },
    },
  });
};

function sendQuickCheckoutEvent() {
  const funnelType = getCheckoutFunnelType();
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 2,
          option: funnelType,
        },
      },
    },
  });
}

function shippingBillingPageView() {
  const funnelType = getCheckoutFunnelType();
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 3,
          option: funnelType,
        },
      },
    },
  });
}

function reviewPaymentPageView() {
  const funnelType = getCheckoutFunnelType();
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        actionField: {
          step: 4,
          option: funnelType,
        },
      },
    },
  });
}

function getCartAndSendEvent(...args) {
  const xhttp = new XMLHttpRequest();
  xhttp.timeout = 5000;
  xhttp.onreadystatechange = (event) => {
    if (
      event.currentTarget.readyState === 4 &&
      event.currentTarget.status === 200
    ) {
      let cartContent = event.currentTarget.response;
      cartContent = JSON.parse(cartContent);
      const cartItems = cartContent.cartContent;
      const discountCodes = cartContent.activeDiscountCodes;
      let itemList = "";
      let totalCost = 0;

      cartItems.forEach((product, index) => {
        totalCost += product.price * product.quantity;
        itemList += product.item;
        if (index !== cartContent.length - 1) {
          itemList += ",";
        }
      });
      totalCost = Number(totalCost).toFixed(2);

      args.forEach((callback) => {
        callback(itemList, totalCost, discountCodes);
      });
    }
  };
  xhttp.open("GET", "/api:cart/getcartwithfeedidentifier/", true);
  xhttp.send();
}

export const sendAddPaymentInfoEvent = (itemList, totalCost, discountCodes) => {
  window.dataLayer.push({
    event: "add_payment_info",
    ecommerce: {
      items: [itemList],
      value: totalCost,
      coupon: [discountCodes],
    },
  });
};

export function fbPaymentInfo(itemList, totalCost) {
  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "AddPaymentInfo", {
      value: totalCost,
      currency: "USD",
      content_ids: [itemList],
      content_type: "product",
    });
  }
}

const addPaymentInfo = (paymentType, isQuickCheckout) => {
  window.dataLayer.push({
    payment_type: paymentType,
    quick_checkout: isQuickCheckout,
  });

  if (session.getItem("payment_added") == null) {
    getCartAndSendEvent(fbPaymentInfo, sendAddPaymentInfoEvent);
    session.setItem("payment_added", 1);
  } else {
    getCartAndSendEvent(sendAddPaymentInfoEvent);
  }
};

function fbInitiateCheckout(itemList, totalCost) {
  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "InitiateCheckout", {
      value: totalCost,
      currency: "USD",
      content_ids: [itemList],
      content_type: "product",
    });
  }
}

export const initiateFacebookCheckout = (itemNumbers, total) =>
  fbInitiateCheckout(itemNumbers, total);

function initiateLegacyFacebookCheckout() {
  if (session.getItem("checkout_initiated") == null) {
    getCartAndSendEvent(fbInitiateCheckout);
    session.setItem("checkout_initiated", 1);
  }
}

function setViewCartShipping() {
  const shippingTiers = document.querySelector(".is-selected label input");
  if (shippingTiers !== null) {
    const selectedShippingTier = shippingTiers.value;
    session.setItem("shipping", selectedShippingTier);
  }
}

function setViewInfoShipping() {
  const shippingOptions = document.getElementById("shipping_options");
  let shippingTier = session.getItem("shipping");

  if (shippingOptions !== null) {
    if (shippingOptions.length > 0) {
      for (let i = 0; i < shippingOptions.length; i += 1) {
        if (shippingOptions[i].selected) {
          shippingTier = shippingOptions[i].value;
        }
      }
    }
  }
  session.setItem("shipping", shippingTier);
}

const addShippingInfo = (itemList, totalCost, discountCodes) => {
  const shipping = session.getItem("shipping");
  let quickCheckout = false;

  if (session.getItem("quick_checkout")) {
    quickCheckout = true;
  }

  window.dataLayer.push({
    event: "add_shipping_info",
    ecommerce: {
      items: itemList,
      value: totalCost,
      coupon: discountCodes,
      shipping_tier: shipping,
      quick_checkout: quickCheckout,
    },
  });
};

function handlePageView(pageInfo) {
  if (typeof pageInfo.page_type !== "undefined") {
    switch (pageInfo.page_type) {
      case "viewcart.cfm": {
        // give the view cart js a bit of time to run before this
        setTimeout(viewCartPageView, 1000);

        const checkoutButtons = document.getElementsByClassName(
          "standardCheckoutButton"
        );
        if (checkoutButtons !== null) {
          for (let i = 0; i < checkoutButtons.length; i += 1) {
            checkoutButtons[i].addEventListener("click", () => {
              setViewCartShipping();
            });
          }
        }

        break;
      }
      case "/shipping-billinginfo.cfm": {
        shippingBillingPageView();
        initiateLegacyFacebookCheckout();

        const continueToPaymentBtn = document.getElementById(
          "continue_to_payment"
        );
        if (continueToPaymentBtn !== null) {
          continueToPaymentBtn.addEventListener("click", () => {
            getCartAndSendEvent(addShippingInfo);
          });
        }

        break;
      }
      case "viewinfo.cfm": {
        reviewPaymentPageView();
        initiateLegacyFacebookCheckout();
        const ccField = document.getElementById("card_number");
        if (ccField !== null) {
          ccField.addEventListener("focusout", () => {
            let ccType = document.querySelector("ul.payment > li.active");
            if (ccType === null) {
              ccType = "credit_card";
            } else {
              ccType = ccType.innerText;
            }
            addPaymentInfo(ccType, false);
          });
        }

        const savedccField = document.querySelector(
          "select[name=storedCCPayment]"
        );
        if (savedccField !== null) {
          savedccField.addEventListener("change", () => {
            if (savedccField.value !== "") {
              addPaymentInfo("credit_card", false);
            }
          });
        }

        const altPaymentCheckbox = document.querySelector(
          "input[name=altpayment_confirm]"
        );
        if (altPaymentCheckbox !== null) {
          altPaymentCheckbox.addEventListener("change", () => {
            if (altPaymentCheckbox.checked) {
              addPaymentInfo("altpayment", false);
            }
          });
        }

        let isLoggedIn = false;
        const { index } = Store.getState().user;
        isLoggedIn = index > 0;

        if (isLoggedIn === true) {
          setViewInfoShipping();
          getCartAndSendEvent(addShippingInfo);
        }

        break;
      }
      default:
        break;
    }
  }
}

export const emailSignupEvent = (email) => {
  window.dataLayer.push({ event: "emailsignup" });
  sendHashedCustomerEmailSignupEvent(email);
  sendFacebookPixelLeadEvent();
};

function shippingBillingEmailSignup() {
  if (document.getElementById("ReceiveCoupons").value === "true") {
    emailSignupEvent();
  }
}

export const sendFacebookPixelInternalUserEvent = async () => {
  const eventId = getRandomNumberString();

  if (typeof fbq === "function") {
    fbq("trackCustom", getFacebookPixelId(), "InternalUser", {
      eventID: eventId,
    });
    sendFacebookPixelServerEvent(
      "InternalUser",
      eventId,
      getFacebookPixelUserData()
    );
  }
};

export const sendFacebookPixelLeadEvent = async () => {
  const eventId = getRandomNumberString();

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "Lead", { eventID: eventId });
    sendFacebookPixelServerEvent("Lead", eventId, getFacebookPixelUserData());
  }
};

export const sendFacebookPixelPageViewEvent = async () => {
  const eventId = getRandomNumberString();

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "PageView", { eventID: eventId });
    sendFacebookPixelServerEvent(
      "PageView",
      eventId,
      getFacebookPixelUserData()
    );
  }
};

export const sendFacebookPixelViewContentEvent = async (feedIdentifier) => {
  const eventId = getRandomNumberString();

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "ViewContent", {
      content_ids: [feedIdentifier],
      content_type: "product",
      eventID: eventId,
    });

    sendFacebookPixelServerEvent(
      "ViewContent",
      eventId,
      getFacebookPixelUserData(),
      {
        content_ids: [feedIdentifier],
        content_type: "product",
      }
    );
  }
};

export const sendFacebookPixelAddToCartEvent = async (
  feedIdentifiers,
  price
) => {
  const eventId = getRandomNumberString();
  const priceOfItemAddedToCart = MarketingEventHelpers.createItemPriceWithTrailingZero(
    parseFloat(price)
  );

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "AddToCart", {
      content_ids: feedIdentifiers,
      value: priceOfItemAddedToCart,
      currency: "USD",
      content_type: "product",
      eventID: eventId,
    });

    sendFacebookPixelServerEvent(
      "AddToCart",
      eventId,
      getFacebookPixelUserData(),
      {
        content_ids: feedIdentifiers,
        value: priceOfItemAddedToCart,
        currency: "USD",
        content_type: "product",
      }
    );
  }
};

export const sendFacebookPixelPurchaseEvent = async (
  orderNumber,
  feedIdentifiers,
  orderValueSubtotal,
  isFirstTimePurchaser
) => {
  const orderValueSubtotalFloat = MarketingEventHelpers.createItemPriceWithTrailingZero(
    parseFloat(orderValueSubtotal)
  );

  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "Purchase", {
      content_ids: feedIdentifiers,
      value: orderValueSubtotalFloat,
      currency: "USD",
      content_type: "product",
      is_first_time_purchase: isFirstTimePurchaser,
      eventID: orderNumber,
    });

    sendFacebookPixelServerEvent(
      "Purchase",
      orderNumber,
      getFacebookPixelUserData(),
      {
        content_ids: feedIdentifiers,
        value: orderValueSubtotalFloat,
        currency: "USD",
        content_type: "product",
        is_first_time_purchase: isFirstTimePurchaser,
      }
    );
  }
};

const waitForFacebookPixel = async () => {
  /* eslint-disable */
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  );
  /* eslint-enable */
};

const loadFacebookPixel = async () => {
  await waitForFacebookPixel();

  fbq("dataProcessingOptions", ["LDU"], 0, 0);
  fbq("init", getFacebookPixelId(), getFacebookPixelUserData());

  sendFacebookPixelPageViewEvent();

  if (getIsInternalUserStatus()) {
    sendFacebookPixelInternalUserEvent();
  }

  if (window.fbEventQueue) {
    window.fbEventQueue.forEach((event) => {
      fbq(
        "trackSingle",
        getFacebookPixelId(),
        event.eventName,
        event.eventParams
      );
    });
  }
};

const trackGoogleAdsConversion = () => {
  if (
    typeof window.google_trackConversion === "function" &&
    typeof gacData !== "undefined" &&
    gacData.shouldIncludeTag &&
    gacData.conversionId > 0
  ) {
    const {
      memberType,
      ip,
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      company_type,
    } = Store.getState().user.analyticsData;
    const info = {
      ...gacData.tags,
      memberType,
      ip,
    };

    // eslint-disable-next-line camelcase
    if (company_type && company_type.length > 0) {
      // eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
      info.businessCategory = company_type;
    }

    const conversionData = {
      google_conversion_id: gacData.conversionId,
      google_conversion_label: gacData.conversionLabel,
      google_custom_params: info,
      google_remarketing_only: true,
    };

    if (info.hashedId > 0) {
      conversionData.google_user_id = info.hashedId;
    }

    window.google_trackConversion(conversionData);
  }
};

export const loadUserAnalytics = async () => {
  Store.subscribe(CheckForUserDataChangesAndUpdate);

  if (typeof window.dataLayer[0] !== "undefined") {
    handlePageView(window.dataLayer[0]);
  }

  const promoElements = document.getElementsByClassName("gtm-promo");
  const promoObserver = new window.IntersectionObserver(
    (entries) => {
      for (let i = 0; i < entries.length; i += 1) {
        if (entries[i].isIntersecting === true) {
          setTimeout(() => promotionView(entries[i].target));
        }
      }
    },
    { threshold: [0.8] }
  );

  window.addEventListener("pageshow", () => {
    for (let i = 0; i < promoElements.length; i += 1) {
      promoElements[i].addEventListener("click", (e) => {
        promotionClick(e.currentTarget);
      });
      promoObserver.observe(promoElements[i]);
    }
  });

  const wishlistForm = document.querySelector(".gtm-atc-wishlist");
  if (wishlistForm) {
    wishlistForm
      .querySelector("#btnAddAllItemsToCart")
      .addEventListener("click", () => {
        multipleATCEvent(wishlistForm.querySelectorAll(".gtm-product"));
      });
  }

  const quickCheckoutButton = document.getElementsByClassName("gtm-quick-co");
  if (quickCheckoutButton.length === 1) {
    quickCheckoutButton[0].addEventListener("click", () => {
      let ccType = document.querySelector(
        "button.btn-select > span.credit-icon"
      );
      if (ccType === null) {
        ccType = "credit_card";
      } else {
        ccType = ccType.innerText;
      }
      sendQuickCheckoutEvent();
      initiateLegacyFacebookCheckout();
      addPaymentInfo(ccType, true);
      session.setItem("quick_checkout", true);
      setViewCartShipping();
      getCartAndSendEvent(addShippingInfo);
    });
  }

  const rrButton = document.getElementsByClassName("gtm-rr-atc");
  if (rrButton.length === 1) {
    rrButton[0].addEventListener("click", async () => {
      const rrProducts = document.getElementsByClassName("gtm-product");
      const items = [];
      for (let i = 0; i < rrProducts.length; i += 1) {
        const rrQty = rrProducts[i].querySelectorAll(".input-mini")[0].value;
        if (rrQty > 0) {
          items.push({
            itemNumber: rrProducts[i].getAttribute("data-item-number"),
            quantity: rrQty,
          });
        }
      }
      if (items.length > 0) {
        const formattedProducts = await createProducts(items);
        formattedProducts.forEach((product) => sendAddToCartEvents([product]));
      }
    });
  }

  const shipBilForm = document.getElementById("billing_shipping_form");
  if (shipBilForm !== null) {
    shipBilForm.addEventListener("submit", shippingBillingEmailSignup);
  }

  const footerSignupForm = document.getElementById("footerEmailSignup");
  if (footerSignupForm !== null) {
    footerSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const emailSignupForm = document.getElementById("emailform");
  if (emailSignupForm !== null) {
    emailSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const outletEmailSignupForm = document.getElementById("outletEmailForm");
  if (outletEmailSignupForm !== null) {
    outletEmailSignupForm.addEventListener("submit", emailSignupEvent);
  }

  const productRecommendationsEmailform = document.getElementById(
    "productRecommendationsEmailform"
  );
  if (productRecommendationsEmailform !== null) {
    productRecommendationsEmailform.addEventListener(
      "submit",
      emailSignupEvent
    );
  }

  trackGoogleAdsConversion();

  loadFacebookPixel();

  await new PinterestTrackingPixel(getUserForPinterestEvent()?.em).load();

  trackYmanCarouselViews();

  bindYmanClickEvent();

  trackRPFYCarouselViews();

  addPriceSpiderTokenToSessionStorage();

  await getDynamicData;
  CheckForUserDataChangesAndUpdate();
  sendGtmEvent({ event: "CustomerDataLoaded" });

  new PinterestApiEvent(
    "page_visit",
    new PinterestTagPageVisitEvent()
  ).sendEvent();
};

window.addEventListener("load", () => {
  bindTrackingForElements("[gtm-tracking-number]", getDataForProductsInRow, 80);
  bindTrackingForElements("[gtm-carousel-name]", trackCarouselImpression, 80);
  bindAddToCartButtonClicksForCarouselTracking();
});
