import React from "react";
import { cls } from "@wss/cls";
import PlusBadge from "../Logos/PlusBadge";
import Chevron from "../icons/Chevron";

interface PlusEssentialsAdWithCampaignDataProps {
  adCampaignData: {
    name: string;
    tabletImage: string;
    desktopImage: string;
    mobileBackgroundImage: string;
    link: string;
    couponCode: string;
    dollarAmountSaved: number;
    endDate: string;
    id: number;
    trackingTier: number;
  };
  modifyNameAndStyling: boolean;
}

const PlusEssentialsAdWithCampaignData = ({
  adCampaignData,
  modifyNameAndStyling,
}: PlusEssentialsAdWithCampaignDataProps) => {
  const {
    couponCode,
    dollarAmountSaved,
    link,
    name,
    trackingTier,
  } = adCampaignData;

  let modifiedName = name;

  if (modifyNameAndStyling) {
    switch (name) {
      case "non-dairy milk":
        modifiedName = "non-dairy";
        break;
      case "flavoring syrups":
        modifiedName = "syrups";
        break;
      case "spreads":
        modifiedName = "spreads";
        break;
      default:
        break;
    }
  }

  return (
    <a
      data-testid="global-plus-essentials-ad"
      id={`global-plus-essentials-${modifiedName.replace(
        " ",
        "-"
      )}-tier${trackingTier}`}
      href={link}
      className={`global-plus-essentials-${modifiedName.replace(" ", "-")}`}
    >
      <div className="text-white text-xs hover:underline flex justify-center items-center h-[17px] whitespace-pre">
        <PlusBadge classNames="pr-2" />
        Get ${dollarAmountSaved} in FREE{" "}
        <span className="capitalize">{name}</span>
        <span> | </span>
        <span
          className={cls("font-bold", modifyNameAndStyling && "text-[#29C5FF]")}
        >
          Use Code: {couponCode}
        </span>
        <Chevron
          strokeColor={modifyNameAndStyling ? "#29C5FF" : "#FFFFFF"}
          classNames="pl-2"
        />
      </div>
    </a>
  );
};

export default PlusEssentialsAdWithCampaignData;
