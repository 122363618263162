import React from "react";
import Chevron from "./icons/Chevron";
import PhoneBadge from "./Logos/PhoneBadge";

interface PhoneAppProps {
  isPlusEssentials?: boolean;
}

const PhoneApp = ({ isPlusEssentials }: PhoneAppProps) => {
  const changeStylesDependingOnEvent = (
    plusEssentialColor: string,
    alertBannerDefaultColor: string
  ) => {
    if (isPlusEssentials) {
      return plusEssentialColor;
    }
    return alertBannerDefaultColor;
  };

  return (
    <a
      aria-label="get app"
      href="/services/app/"
      className={`inline-flex items-center font-semibold text-xs hover:underline ${
        isPlusEssentials ? "text-white" : "text-gray-800"
      }`}
      data-testid="get-app-ad-global-header"
    >
      <PhoneBadge classNames="pr-1" />
      Get the App
      <Chevron
        classNames="pl-2"
        strokeColor={changeStylesDependingOnEvent("#FFFFFF", "#3b4238")}
      />
    </a>
  );
};

export default PhoneApp;
