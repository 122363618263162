type CustomBingEvent = {
  el: string;
  pagetype: string;
  prodid: string[];
};

type BingEvent = CustomBingEvent;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    uetq?: BingEvent[];
  }
}

const sendBingEvent = (bingEvent: BingEvent) => {
  window.uetq?.push(bingEvent);
};

export const sendBingViewCartEvent = (productsFeedIds: string[]) => {
  if (productsFeedIds.length > 0) {
    sendBingEvent({
      el: "cart",
      pagetype: "cart",
      prodid: productsFeedIds,
    });
  }
};

export const sendBingProductPageViewEvent = (productsFeedId: string) => {
  if (productsFeedId.length > 0) {
    sendBingEvent({
      el: "product page",
      pagetype: "product",
      prodid: [productsFeedId],
    });
  }
};
