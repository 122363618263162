import React from "react";

interface StandardLogoProps {
  fillColor: string;
  fillSecondColor: string;
  logoClassNames?: string;
  width?: string;
  height?: string;
}

const StandardLogo = ({
  fillColor,
  fillSecondColor,
  logoClassNames,
  width = "100%",
  height = "30",
}: StandardLogoProps) => (
  <svg
    aria-hidden="true"
    className={logoClassNames}
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 336 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9422 29.9231L19.8702 9.70613L14.8368 29.9231H8.28622L0 0.483887H6.89768L11.9697 21.7605L17.4788 0.483887H22.2937L27.7643 21.7605L32.7913 0.483887H39.734L31.4928 29.9231H24.9422Z"
      fill={fillColor}
    />
    <path
      d="M37.2652 19.2424C37.2652 13.0616 41.7329 8.07764 48.1099 8.07764C54.4869 8.07764 58.6075 12.8457 58.6075 19.818V21.0542H43.0379C43.385 23.7489 45.5128 25.9989 49.0677 25.9989C50.8484 25.9989 53.3169 25.2075 54.6605 23.8797L57.0904 27.4967C55.0076 29.4392 51.7098 30.453 48.457 30.453C42.0801 30.453 37.2652 26.0839 37.2652 19.2424ZM48.1099 12.5318C44.6836 12.5318 43.205 15.1349 42.9479 17.2541H53.2719C53.1434 15.2265 51.7548 12.5318 48.1099 12.5318Z"
      fill={fillColor}
    />
    <path
      d="M61.2947 29.9231V0.483887H66.8038V11.3413C68.4495 9.17634 70.7959 8.07752 73.2644 8.07752C78.5999 8.07752 82.547 12.3158 82.547 19.2881C82.547 26.2604 78.5549 30.4529 73.2644 30.4529C70.7509 30.4529 68.4495 29.3083 66.8038 27.1891V29.9231H61.2947ZM71.5287 25.469C74.7365 25.469 76.8643 22.9966 76.8643 19.2881C76.8643 15.5796 74.7365 13.068 71.5287 13.068C69.748 13.068 67.7102 14.1276 66.8038 15.5403V23.0882C67.7167 24.4552 69.7545 25.469 71.5287 25.469Z"
      fill={fillColor}
    />
    <path
      d="M83.5884 27.1437L85.9733 23.2128C87.4904 24.6713 90.6146 26.1233 93.2181 26.1233C95.6031 26.1233 96.7345 25.1553 96.7345 23.8276C96.7345 20.2957 84.2826 23.167 84.2826 14.7819C84.2826 11.2042 87.3619 8.07129 92.871 8.07129C96.4259 8.07129 99.248 9.30746 101.331 10.9819L99.1194 14.8212C97.8594 13.4542 95.4745 12.3488 92.916 12.3488C90.9232 12.3488 89.6182 13.2776 89.6182 14.468C89.6182 17.6467 102.025 14.9978 102.025 23.6052C102.025 27.4903 98.7723 30.4467 92.961 30.4467C89.3161 30.4467 85.8062 29.2105 83.5884 27.1371"
      fill={fillColor}
    />
    <path
      d="M106.757 24.6251V13.5061H103.285V8.60721H106.757V2.77954H112.311V8.60721H116.56V13.5061H112.311V23.1273C112.311 24.4485 113.005 25.4689 114.22 25.4689C115.043 25.4689 115.827 25.1615 116.129 24.8475L117.299 29.0858C116.476 29.838 114.998 30.4528 112.703 30.4528C108.839 30.4528 106.763 28.4252 106.763 24.6251"
      fill={fillColor}
    />
    <path
      d="M132.476 29.9231V27.7189C131.043 29.4391 128.574 30.4529 125.842 30.4529C122.499 30.4529 118.552 28.1572 118.552 23.3891C118.552 18.621 122.499 16.5018 125.842 16.5018C128.619 16.5018 131.094 17.4306 132.476 19.105V16.2337C132.476 14.0687 130.657 12.656 127.88 12.656C125.624 12.656 123.541 13.4931 121.767 15.1741L119.684 11.4198C122.242 9.12403 125.54 8.06445 128.838 8.06445C133.608 8.06445 137.992 10.007 137.992 16.1421V29.91H132.483L132.476 29.9231ZM132.476 24.8018V22.1529C131.563 20.9167 129.828 20.3019 128.054 20.3019C125.887 20.3019 124.107 21.4465 124.107 23.5264C124.107 25.6063 125.887 26.6594 128.054 26.6594C129.834 26.6594 131.57 26.038 132.476 24.8084"
      fill={fillColor}
    />
    <path
      d="M155.426 29.9232V27.2285C153.992 28.8637 151.479 30.453 148.053 30.453C143.456 30.453 141.283 27.8956 141.283 23.7424V8.60742H146.793V21.5382C146.793 24.4945 148.31 25.4691 150.656 25.4691C152.784 25.4691 154.475 24.2787 155.426 23.0425V8.60742H160.935V29.9232H155.426Z"
      fill={fillColor}
    />
    <path
      d="M165.101 29.9232V8.60742H170.61V11.518C172.082 9.62122 174.685 8.07764 177.289 8.07764V13.5521C176.897 13.4605 176.376 13.4213 175.772 13.4213C173.953 13.4213 171.523 14.4809 170.61 15.8479V29.9298H165.101V29.9232Z"
      fill={fillColor}
    />
    <path
      d="M191.991 29.9231V27.7189C190.557 29.4391 188.088 30.4529 185.356 30.4529C182.014 30.4529 178.067 28.1572 178.067 23.3891C178.067 18.621 182.014 16.5018 185.356 16.5018C188.133 16.5018 190.602 17.4306 191.991 19.105V16.2337C191.991 14.0687 190.171 12.656 187.394 12.656C185.138 12.656 183.055 13.4931 181.281 15.1741L179.198 11.4198C181.756 9.12403 185.054 8.06445 188.352 8.06445C193.122 8.06445 197.506 10.007 197.506 16.1421V29.91H191.997L191.991 29.9231ZM191.991 24.8018V22.1529C191.078 20.9167 189.342 20.3019 187.568 20.3019C185.401 20.3019 183.621 21.4465 183.621 23.5264C183.621 25.6063 185.401 26.6594 187.568 26.6594C189.348 26.6594 191.084 26.038 191.991 24.8084"
      fill={fillColor}
    />
    <path
      d="M215.808 29.9232V17.0383C215.808 14.0819 214.291 13.0681 211.945 13.0681C209.778 13.0681 208.087 14.3043 207.175 15.5862V29.9298H201.665V8.60742H207.175V11.3872C208.518 9.75203 211.077 8.07764 214.503 8.07764C219.099 8.07764 221.311 10.7266 221.311 14.8733V29.9232H215.802H215.808Z"
      fill={fillColor}
    />
    <path
      d="M227.431 24.6251V13.5061H223.959V8.60721H227.431V2.77954H232.985V8.60721H237.234V13.5061H232.985V23.1273C232.985 24.4485 233.679 25.4689 234.894 25.4689C235.717 25.4689 236.501 25.1615 236.803 24.8475L237.973 29.0858C237.15 29.838 235.672 30.4528 233.377 30.4528C229.513 30.4528 227.437 28.4252 227.437 24.6251"
      fill={fillColor}
    />
    <path
      d="M240.853 25.7765L242.46 23.8797C244.324 26.0839 247.359 28.1638 251.486 28.1638C257.425 28.1638 259.123 24.8085 259.123 22.2904C259.123 13.6437 241.772 18.1436 241.772 7.85526C241.772 3.04138 246.021 0 251.273 0C255.613 0 258.82 1.54358 261.077 4.14674L259.431 5.99773C257.348 3.44035 254.398 2.28921 251.145 2.28921C247.281 2.28921 244.376 4.53917 244.376 7.7179C244.376 15.2658 261.726 11.119 261.726 22.1072C261.726 25.9008 259.251 30.4465 251.447 30.4465C246.677 30.4465 243.077 28.5039 240.866 25.77"
      fill={fillSecondColor}
    />
    <path
      d="M266.753 25.8158V10.635H263.282V8.60746H266.753V2.77979H269.054V8.60746H273.304V10.635H269.054V25.4626C269.054 27.1827 269.749 28.3731 271.221 28.3731C272.217 28.3731 273.085 27.8891 273.567 27.3593L274.435 29.1253C273.612 29.9233 272.609 30.4465 270.88 30.4465C268.103 30.4465 266.759 28.7263 266.759 25.8092"
      fill={fillSecondColor}
    />
    <path
      d="M274.866 19.2424C274.866 13.0223 278.813 8.07764 284.926 8.07764C291.04 8.07764 295.032 13.0223 295.032 19.2424C295.032 25.4625 291.13 30.453 284.926 30.453C278.723 30.453 274.866 25.4625 274.866 19.2424ZM292.608 19.2424C292.608 14.5201 289.876 10.151 284.933 10.151C279.989 10.151 277.296 14.5201 277.296 19.2424C277.296 23.9647 280.073 28.3796 284.933 28.3796C289.793 28.3796 292.608 24.0105 292.608 19.2424Z"
      fill={fillSecondColor}
    />
    <path
      d="M297.809 29.9234V8.6076H300.065V12.2703C301.846 9.88956 304.231 8.16284 307.136 8.16284V10.681C306.744 10.5959 306.442 10.5959 306.011 10.5959C303.884 10.5959 301.023 12.5385 300.065 14.3895V29.9234H297.809Z"
      fill={fillSecondColor}
    />
    <path
      d="M307.31 19.2424C307.31 13.0616 311.604 8.07764 317.415 8.07764C323.792 8.07764 327.309 13.2382 327.309 19.3732V19.9946H309.785C310.004 24.6319 313.038 28.4712 317.981 28.4712C320.63 28.4712 323.053 27.4574 324.834 25.5149L326.004 27.0127C323.921 29.2168 321.279 30.453 317.808 30.453C311.694 30.453 307.31 25.8157 307.31 19.2424ZM317.377 10.066C312.433 10.066 309.959 14.4809 309.785 18.1894H325.007C324.962 14.5724 322.622 10.066 317.37 10.066"
      fill={fillSecondColor}
    />
    <path
      d="M336 10.9228C336 12.8588 334.509 14.3762 332.567 14.3762C330.626 14.3762 329.115 12.8588 329.115 10.9228C329.115 8.98677 330.645 7.51514 332.567 7.51514C334.489 7.51514 336 9.03256 336 10.9228ZM329.97 10.9228C329.97 12.4402 331.076 13.6437 332.587 13.6437C334.097 13.6437 335.139 12.4402 335.139 10.9424C335.139 9.44461 334.052 8.19536 332.561 8.19536C331.07 8.19536 329.964 9.42499 329.964 10.9162L329.97 10.9228ZM332.034 12.7084H331.256V9.30072C331.564 9.23531 331.989 9.19607 332.542 9.19607C333.178 9.19607 333.461 9.30072 333.705 9.44461C333.892 9.58851 334.033 9.86321 334.033 10.1902C334.033 10.5631 333.75 10.8574 333.339 10.9817V11.0209C333.667 11.1452 333.847 11.3937 333.949 11.8516C334.052 12.3683 334.11 12.5776 334.194 12.7018H333.358C333.255 12.5776 333.197 12.2636 333.094 11.8712C333.03 11.4984 332.831 11.3283 332.4 11.3283H332.034V12.7018V12.7084ZM332.053 10.7724H332.419C332.85 10.7724 333.197 10.6285 333.197 10.2753C333.197 9.96132 332.972 9.75202 332.484 9.75202C332.278 9.75202 332.137 9.77164 332.053 9.79126V10.7658V10.7724Z"
      fill={fillSecondColor}
    />
  </svg>
);

export default StandardLogo;
