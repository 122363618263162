import React, { useState, useEffect } from "react";
import { cls } from "cls";
import { Provider } from "react-redux";
import isLokiRunning from "@loki/is-loki-running";
import Store from "~/common/Store";
import AlertBanner from "./AlertBanner";
import Banner from "./Banner";
import { NavItemData } from "~/common/GlobalHeaderContainer/Nav/Item";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import connectUserAndChat, {
  User,
  Chat,
} from "~/common/Store/connectUserAndChat";
import UnsupportedBrowserBanner from "../Banner/UnsupportedBrowserBanner";
import SkeletonContainer from "./SkeletonContainer";
import fetchPageCreatorBanner from "./AlertBanner/fetchPageCreatorBanner";
import { PageCreatorBanner } from "./AlertBanner/PageCreatorBanner";

/*
Handles loading the page creator banner as the page renders.
Subscriptions are updated once the request completes
*/
type PageCreatorBannerResult = {
  data: PageCreatorBanner | null;
  status: "loading" | "success" | "error";
};
const pageCreatorBannerResult: PageCreatorBannerResult = {
  data: null,
  status: "loading",
};
let subscription: ((data: PageCreatorBannerResult) => void) | null = null;
if (!isLokiRunning()) {
  fetchPageCreatorBanner()
    .then((data) => {
      pageCreatorBannerResult.status = "success";
      pageCreatorBannerResult.data = data;
    })
    .catch(() => {
      pageCreatorBannerResult.status = "error";
    })
    .finally(() => {
      subscription?.(pageCreatorBannerResult);
    });
}

interface HeaderProps {
  user: User;
  chat: Chat;
  navDataItems: NavItemData[];
  checkout: boolean;
  mobileExpandedSection: string;
  setMobileExpandedSection: React.Dispatch<React.SetStateAction<string>>;
  mobileNavOpen: boolean;
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mounted: boolean;
  productCount: number;
  imageSearchFeatureFlag?: boolean;
}

const GlobalHeaderContainer = ({
  user,
  chat,
  navDataItems,
  checkout,
  mobileNavOpen,
  setMobileNavOpen,
  mounted,
  productCount,
  imageSearchFeatureFlag,
}: HeaderProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [checkoutStyling, setCheckoutStyling] = useState("");
  const [isEventDayDataLoading, setIsEventDayDataLoading] = useState<boolean>(
    pageCreatorBannerResult.status === "loading"
  );

  useEffect(() => {
    if (document.location.pathname.includes("/shipping-billinginfo.cfm")) {
      setCheckoutStyling("mb-4");
    }
    if (document.location.pathname.includes("/viewinfo.cfm")) {
      setCheckoutStyling("mb-4 xs:mb-0");
    }
  }, []);

  const pageCreatorBanner = pageCreatorBannerResult.data;
  const eventDayBannerVisible = !!pageCreatorBannerResult.data;

  useEffect(function initSubscribeToPageCreatorRequest() {
    if (pageCreatorBannerResult.status === "loading") {
      subscription = function handleEventDayRequest() {
        setIsEventDayDataLoading(false);
        subscription = null;
      };
    } else {
      setIsEventDayDataLoading(false);
    }
  }, []);

  useEffect(() => {
    const pageContainer = document.getElementsByClassName("page-container")[0];
    let nextSibling = pageContainer?.nextElementSibling;
    const globalBreadcrumbs = document.getElementsByClassName(
      "global-breadcrumb"
    )[0];
    const elementsToInert = [];

    while (nextSibling) {
      elementsToInert.push(nextSibling);
      nextSibling = nextSibling.nextElementSibling;
    }

    if (elementsToInert) {
      if (mobileNavOpen) {
        elementsToInert.forEach((element) => element.setAttribute("inert", ""));
        globalBreadcrumbs?.setAttribute("inert", "");
      } else {
        elementsToInert.forEach((element) => element.removeAttribute("inert"));
        globalBreadcrumbs?.removeAttribute("inert");
      }
    }
  }, [mobileNavOpen]);

  return (
    <Provider store={Store}>
      <UnsupportedBrowserBanner mounted={mounted} user={user} />
      {mounted ? (
        <div
          data-testid="alert-banner-container"
          className="lt:block hidden"
          {...{ inert: mobileNavOpen ? "" : undefined }}
        >
          <AlertBanner
            chat={chat}
            user={user}
            mounted={mounted}
            checkout={checkout}
            isEventDayDataLoading={isEventDayDataLoading}
            pageCreatorBanner={pageCreatorBanner}
            eventDayBannerVisible={eventDayBannerVisible}
          />
        </div>
      ) : (
        <SkeletonContainer
          classNames="hidden lt:block"
          style={{ minHeight: "32px" }}
        />
      )}
      <Banner
        user={user}
        chat={chat}
        mounted={mounted}
        setMobileNavOpen={setMobileNavOpen}
        mobileNavOpen={mobileNavOpen}
        checkout={checkout}
        productCount={productCount}
        imageSearchFeatureFlag={imageSearchFeatureFlag}
      />
      <DesktopNav navDataItems={navDataItems} checkout={checkout} />
      <MobileNav
        user={user}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        mobileNavOpen={mobileNavOpen}
        mounted={mounted}
        navDataItems={navDataItems}
      />
      {mounted && !checkout ? (
        <div
          className="lt:hidden block"
          {...{ inert: mobileNavOpen ? "" : undefined }}
        >
          <AlertBanner
            chat={chat}
            user={user}
            mounted={mounted}
            checkout={checkout}
            isEventDayDataLoading={isEventDayDataLoading}
            pageCreatorBanner={pageCreatorBanner}
            eventDayBannerVisible={eventDayBannerVisible}
          />
        </div>
      ) : (
        <SkeletonContainer
          classNames={cls("bg-white", "lt:hidden", checkout ? "hidden" : "")}
          style={{ minHeight: "32px" }}
        />
      )}
      <div className={`${checkoutStyling}`} />
    </Provider>
  );
};

export default connectUserAndChat(GlobalHeaderContainer);
