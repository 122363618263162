import React, { ReactNode } from "react";
import debounce from "lodash/debounce";

type CacheOptions = { ttl?: number };

class Cache extends Map {
  set(key: unknown, value: unknown, { ttl }: CacheOptions = {}) {
    super.set(key, value);

    if (ttl) {
      setTimeout(() => {
        this.delete(key);
      }, ttl);
    }
    return this;
  }
}

export const cache = new Cache();

const createLink = (href: string, rel: string) => {
  if (!href || href === document.location.href) return;

  const link = document.createElement("link");
  link.href = href;
  link.rel = rel;

  document.head.appendChild(link);
};

const extractAnchor = (event: React.PointerEvent<HTMLAnchorElement>) => {
  if (!(event.target instanceof HTMLElement)) return null;

  return event.target.closest("a");
};

const handlePrefetch = (event: React.PointerEvent<HTMLAnchorElement>) => {
  if (!(event.target instanceof HTMLElement)) return;

  const anchor = extractAnchor(event);

  if (!anchor) return;

  const { href } = anchor;

  if (cache.get(href)) return;

  cache.set(href, true, { ttl: 60000 });

  createLink(href, "prefetch");
};

const prerender = (event: React.PointerEvent<HTMLAnchorElement>) => {
  const anchor = extractAnchor(event);
  if (!anchor) return;

  createLink(anchor.href, "prerender");
};

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  children?: ReactNode;
  prefetch?: boolean;
  wait?: number;
}

const prefetchInstances: { [key: number]: typeof handlePrefetch } = {};

/**
 * Use this in place of an <a> tag to enable prerendering for a link.
 */
export default (props: Props) => {
  const { children, prefetch = true, wait = 0, ...forwardProps } = props;

  let prefetchInstance = handlePrefetch;

  if (prefetch && wait) {
    if (!prefetchInstances[wait]) {
      prefetchInstances[wait] = debounce(prefetchInstance, wait);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    prefetchInstance = prefetchInstances[wait]!;
  }

  return prefetch ? (
    <a
      {...forwardProps}
      onPointerEnter={(e) => {
        e.persist();
        prefetchInstance(e);
      }}
      onPointerDown={prerender}
    >
      {children}
    </a>
  ) : (
    // Don't pass false prefetch prop.
    <a {...forwardProps}>{children}</a>
  );
};
