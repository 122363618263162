import type { Product } from "../../../userAnalytics";
import type { Tag, Order } from "../MarketingDataDefinitions";
import PinterestApiEvent from "./PinterestApiEvent";

export default class PinterestApiCommerceEvent extends PinterestApiEvent {
  products: Product[];

  order?: Order;

  constructor(
    eventName: string,
    associatedTagEvent: Tag,
    products: Product[],
    order?: Order
  ) {
    super(eventName, associatedTagEvent);
    this.products = products;
    this.order = order;
  }

  private getProductCostAndOrderNumber() {
    return {
      OrderId: this.order?.orderNumber,
      Value: this.order?.orderTotal?.toString(),
    };
  }

  private formatProductsForApiRequest() {
    const contentIds: string[] = [];
    const contents: Array<Dictionary<string>> = [];

    const productsForEvent = {
      customData: {
        Contents: contents,
        ContentIds: contentIds,
        ...this.getProductCostAndOrderNumber(),
        ...{ numItems: this.products.length },
      },
    };

    this.products.forEach((product) => {
      productsForEvent.customData.ContentIds.push(product.id);
      productsForEvent.customData.Contents.push({
        id: product.id,
        itemPrice: product.price,
        quantity: product.quantity ?? "1",
      });
    });

    return productsForEvent;
  }

  protected formatDataForStashApiRequest() {
    const userAndEventData = super.formatDataForStashApiRequest();
    return { ...userAndEventData, ...this.formatProductsForApiRequest() };
  }
}
