import React from "react";
import ct from "class-types.macro";
import { PageCreatorBanner } from "./PageCreatorBanner";

interface Props {
  pageCreatorBanner: PageCreatorBanner | null;
  classNames?: string;
}

function SiteWideEventDayBanner({ pageCreatorBanner, classNames = "" }: Props) {
  const { bannerText, relativeUrl, textColor } = pageCreatorBanner || {
    bannerText: "",
    relativeUrl: "",
    textColor: "",
  };

  return (
    <p
      className={`${ct(
        "font-semibold",
        "text-xs",
        "m-0",
        "text-center",
        "flex-grow-0"
      )} ${classNames}`}
      data-testid="PageCreatorSiteWideBanner"
    >
      <a href={relativeUrl} style={{ color: textColor }}>
        {bannerText} <span className={ct("hover:underline")}>Shop Now</span>
      </a>
    </p>
  );
}

export default SiteWideEventDayBanner;
