/* Pulled from a [Kent C Dobbs](https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript) article */

type ErrorWithMessage = {
  message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
}

/**
 * Used inside of a catch block to get an error from an unknown error.
 * @example
 * try {
 *  someThrowableFn();
 * } catch(error) {
 *  const safeError = toError(error);
 *  // Log message to analytics service
 * }
 */
export function toError(maybeError: unknown): Error {
  if (maybeError instanceof Error) return maybeError;
  if (isErrorWithMessage(maybeError)) return new Error(maybeError.message);

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    return new Error(String(maybeError));
  }
}

/**
 * Used inside of a catch block to get the message from an unknown error.
 * This will handle custom errors as long as the use the `message` property.
 * @example
 * try {
 *  someThrowableFn();
 * } catch(error) {
 *  const message = getErrorMessage(error);
 *  // Log message to analytics service
 * }
 */
export function getErrorMessage(error: unknown): string {
  return toError(error).message;
}
