import { PageCreatorBanner } from "./PageCreatorBanner";

const fetchPageCreatorBanner = async (): Promise<PageCreatorBanner | null> => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const bannerPreviewData = params.get("bannerpreview");
  const response = await fetch(
    `/api/pagecreatorbanner${
      bannerPreviewData
        ? `?bannerpreview=${encodeURIComponent(bannerPreviewData)}`
        : ""
    }`
  );

  const pageCreatorBanner = await response.json<PageCreatorBanner>();

  return pageCreatorBanner;
};

export default fetchPageCreatorBanner;
