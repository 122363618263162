import React from "react";
import ct from "class-types.macro";
import { UserState } from "~/common/Store/models/RootReadonly";

const AccountStepTwo = ({
  setCurrentStep,
  user,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  user: UserState;
}) => {
  return (
    <div className={ct("m-0")}>
      <div
        style={{ background: "#f8f8f8" }}
        className={ct(
          "border-gray-200",
          "border-solid",
          "border-t-0",
          "border-r-0",
          "border-l-0",
          "border-b",
          "py-2",
          "px-4"
        )}
      >
        <button
          style={{ background: "#f8f8f8" }}
          className={ct(
            "border-none",
            "flex",
            "items-center",
            "font-bold",
            "text-lg",
            "p-0",
            "text-left",
            "text-gray-800",
            "w-full"
          )}
          type="button"
          onClick={() => {
            setCurrentStep(1);
          }}
        >
          <svg
            className={ct("pr-1")}
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 12L1 6.5L6 1"
              stroke="#3b4238"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Main Menu
        </button>
      </div>
      <li
        className={ct(
          "flex",
          "items-start",
          "font-semibold",
          "list-none",
          "py-2",
          "px-3",
          "text-gray-800"
        )}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.6665 20.1667C3.6665 18.2218 4.43912 16.3566 5.81439 14.9813C7.18965 13.606 9.05492 12.8334 10.9998 12.8334C12.9448 12.8334 14.81 13.606 16.1853 14.9813C17.5606 16.3566 18.3332 18.2218 18.3332 20.1667H16.4998C16.4998 18.7081 15.9204 17.3091 14.8889 16.2777C13.8575 15.2462 12.4585 14.6667 10.9998 14.6667C9.54115 14.6667 8.1422 15.2462 7.11075 16.2777C6.0793 17.3091 5.49984 18.7081 5.49984 20.1667H3.6665ZM10.9998 11.9167C7.96109 11.9167 5.49984 9.4555 5.49984 6.41675C5.49984 3.378 7.96109 0.916748 10.9998 0.916748C14.0386 0.916748 16.4998 3.378 16.4998 6.41675C16.4998 9.4555 14.0386 11.9167 10.9998 11.9167ZM10.9998 10.0834C13.0257 10.0834 14.6665 8.44258 14.6665 6.41675C14.6665 4.39091 13.0257 2.75008 10.9998 2.75008C8.974 2.75008 7.33317 4.39091 7.33317 6.41675C7.33317 8.44258 8.974 10.0834 10.9998 10.0834Z"
            fill="#3b4238"
          />
        </svg>

        <li className={ct("list-none", "py-1", "px-2", "w-full")}>
          <div className={ct("flex", "items-center", "justify-between")}>
            <a
              className={ct("font-semibold", "text-gray-800")}
              href="/myaccount/"
            >
              Account
            </a>
            <p className={ct("m-0")}>
              <a
                className={ct("text-gray-800", "underline")}
                href="/myaccount/"
              >
                Manage
              </a>
            </p>
          </div>
          <li className={ct("flex", "justify-between", "list-none", "py-3")}>
            <a
              href="/reorder.html"
              className={ct("font-medium", "text-gray-700")}
            >
              Rapid Reorder
            </a>
            <svg
              width="5"
              height="9"
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8L4 4.5L1 1"
                stroke="#3b4238"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </li>
          <li className={ct("flex", "justify-between", "list-none", "py-3")}>
            <a
              href="/myaccount/orders/"
              className={ct("font-medium", "text-gray-700")}
            >
              Orders
            </a>
            <svg
              width="5"
              height="9"
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8L4 4.5L1 1"
                stroke="#3b4238"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </li>
          <li className={ct("flex", "justify-between", "list-none", "py-3")}>
            <a
              href="/wishlist.html"
              className={ct("font-medium", "text-gray-700")}
            >
              Wish Lists
            </a>
            <svg
              width="5"
              height="9"
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8L4 4.5L1 1"
                stroke="#3b4238"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </li>
          {user.orderGuides && (
            <li className={ct("flex", "justify-between", "list-none", "py-3")}>
              <a
                href="/myproducts.html"
                className={ct("font-medium", "text-gray-700")}
              >
                Order Guides
              </a>
              <svg
                width="5"
                height="9"
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8L4 4.5L1 1"
                  stroke="#3b4238"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </li>
          )}
        </li>
      </li>
    </div>
  );
};

export default AccountStepTwo;
