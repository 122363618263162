import { createStore, applyMiddleware, compose } from "redux";
import { save, load } from "redux-localstorage-simple";
import thunk from "redux-thunk";
import isEmpty from "lodash/isEmpty";
import { Root } from "./models/RuntimeValidator";
import rootReducer, { defaultRootObject } from "./rootReducer";
import { RootState } from "./models/RootReadonly";

function localStorageSupported() {
  try {
    const testKey = "test_key";
    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

export { Provider } from "react-redux";

/**
 * localStorage doesn't exist in a server environment so we mock out our cached store
 * and override it if localStorage is available.
 */
let loadStateFromLocalStorage = () => defaultRootObject;

if (localStorageSupported()) {
  /**
   * We hope this is valid RootState, but we'll verify it at runtime before setting it.
   */
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const cachedReduxStore = load() as RootState;

  if (!isEmpty(cachedReduxStore)) {
    try {
      Root?.check(cachedReduxStore);

      loadStateFromLocalStorage = () => cachedReduxStore;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

/**
 * window doesn't exist in server environment.
 */
/* eslint-disable-next-line no-underscore-dangle */
const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const middlewares = [save(), thunk];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const Store = createStore(rootReducer, loadStateFromLocalStorage(), enhancer);

// export store singleton instance
export default Store;
