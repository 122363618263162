export default abstract class MarketingEventHelpers {
  public static removeEmailFromUrl(url: URL): string {
    const regexToReplaceEmails = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    const query = url.search;

    if (query.search(regexToReplaceEmails) > -1) {
      return url.origin + url.pathname;
    }
    return url.origin + url.pathname + query;
  }

  public static generateUniqueEventId(): string {
    return `${Date.now()}${Math.round(Math.random() * 100000)}`;
  }

  public static getCurrentTimeStamp(): number {
    return Math.floor(Date.now() / 1000);
  }

  public static createItemPriceWithTrailingZero(itemPrice: number): string {
    // If value is an integer, use only one zero after the decimal point to match the API calls in C#
    if (Number.isInteger(itemPrice)) {
      return itemPrice.toFixed(1);
    }

    return itemPrice.toString();
  }
}
