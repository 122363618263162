import { storageFactory } from "storage-factory";
import SHA256 from "crypto-js/sha256";
import isEqual from "lodash/isEqual";
import { logException } from "@wss/error-tracking";
import { getErrorMessage } from "@wss/error-tracking/utils";
import { MouseEvent } from "react";
import Environment from "./common/Environment";
import { getDynamicData } from "./common/Store/actions/updateUserAndChatFromOrigin";
import { updateUser } from "./common/Store/actions/user";
import Store from "./common/Store";
import {
  GtmEvent,
  sendGtmEvent,
  carouselIdDimension,
  itemNumberDimension,
  vendorDimension,
  productReviewCountDimension,
  analyticsStorageKey,
  clearEcommerceInDataLayer,
  clearGa4EventInDataLayer,
} from "./analytics/definitions";
import { AnalyticsData } from "./common/Store/models/User";
import { UserAbFeatureOption } from "./common/AbFeature";
import MarketingEventHelpers from "./analytics/MarketingHelpers/MarketingEventHelpers";

const session = storageFactory(() => window.sessionStorage);

export interface Item {
  itemNumber: string;
  quantity?: number;
  price?: number;
  feedIdentifier?: string | number;
}

export interface Product {
  name: string;
  id: string;
  price: string;
  brand: string;
  category: string;
  itemNumber?: string;
  quantity?: string;
  dimension39?: string;
  dimension40?: string;
  dimension52?: string;
  dimension64?: string;
  // eslint-disable-next-line camelcase
  item_category?: string;
  // eslint-disable-next-line camelcase
  item_category2?: string;
  // eslint-disable-next-line camelcase
  item_category3?: string;
  // eslint-disable-next-line camelcase
  item_category4?: string;
  // eslint-disable-next-line camelcase
  item_category5?: string;
  position: number;
  categoryId: number;
}

type FacebookServerUserData = {
  ct?: string;
  country?: string;
  em?: string;
  fn?: string;
  ln?: string;
  ph?: string;
  st?: string;
  zp?: string;
  // eslint-disable-next-line camelcase
  external_id?: string;
  // eslint-disable-next-line camelcase
  client_ip_address: string;
  // eslint-disable-next-line camelcase
  client_user_agent: string;
  facebookClickId?: string;
};

type FacebookServerEvent = {
  /* eslint-disable camelcase */
  event_name: string;
  event_time: number;
  event_id: string;
  event_source_url: string;
  action_source: string;
  user_data: FacebookServerUserData;
  custom_data?: unknown;
};

interface InteractionLookup {
  [itemNumber: string]: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    dataLayer: GtmEvent[];
    google_tag_manager: unknown;
    /* eslint-enable camelcase */
  }
}

window.dataLayer = window.dataLayer || [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const fbq: any;

type Result = {
  description: string;
  feedIdentifier: string;
  price: number;
  brand: string;
  category: string;
  itemNumber: string;
  vendorCode: string;
  productReviewCount: number;
  categoryId: number;
};

export const getProductData = async (itemNumbers: string) => {
  await getDynamicData;

  const formattedItemNumbers = encodeURIComponent(itemNumbers);
  const endpointUrl =
    "/marketing:analytics/getproductdataforanalytics/?itemNumbers=";
  const getProductDataUrl = endpointUrl.concat(formattedItemNumbers);

  return fetch(getProductDataUrl, {
    method: "GET",
  }).then((response) => {
    if (response.ok) {
      return response.json<Result[]>();
    }
    return [];
  });
};

export const getCarouselInteraction = (): InteractionLookup => {
  const sessionValue = session.getItem("carousel") || "{}";
  return JSON.parse(sessionValue);
};

export const createProducts = async (items: Item[]) => {
  const products: Product[] = [];
  const itemNumbers = items.map((item: Item) => item.itemNumber.trim());
  const data = await getProductData(itemNumbers.toString());

  data.forEach((result: Result, index: number) => {
    const productLookup = items.find(
      (item) =>
        item.itemNumber.trim().toUpperCase() ===
        result.itemNumber.trim().toUpperCase()
    );

    const carouselLookup = getCarouselInteraction();
    const carouselId =
      carouselLookup[result.itemNumber.trim().toUpperCase()] || "";

    const categories = result.category.split("/");

    const product: Product = {
      name: result.description,
      id: result.feedIdentifier,
      price: result.price.toString(),
      brand: result.brand,
      category: result.category,
      itemNumber: result.itemNumber,
      [vendorDimension]: result.vendorCode,
      [itemNumberDimension]: result.itemNumber.toUpperCase().trim(),
      [carouselIdDimension]: carouselId,
      [productReviewCountDimension]: result.productReviewCount.toString(),
      item_category: categories[0],
      item_category2: categories[1],
      item_category3: categories[2],
      item_category4: categories[3],
      item_category5: categories[4],
      position: index,
      categoryId: result.categoryId,
    };

    if (productLookup && productLookup.quantity) {
      product.quantity = productLookup.quantity.toString();
    }

    products.push(product);
  });

  return products;
};

export const trackClickThroughStats = (event: MouseEvent) => {
  if (event.currentTarget instanceof HTMLElement) {
    const body = new FormData();

    const { ctt, ctd } = event.currentTarget.dataset;

    if (ctt) body.append("ctt", ctt);
    if (ctd) body.append("ctd", ctd);

    fetch("/marketing:analytics/trackclickthroughstats/", {
      method: "POST",
      body,
      keepalive: true,
    });
  }
};

const carouselView = (
  carouselAnchor: HTMLElement,
  observer: IntersectionObserver,
  eventName: string
) => {
  if (eventName === "recAiEvent") {
    const { placementId, category } = carouselAnchor.dataset;
    const { id } = carouselAnchor;
    if (placementId || id) {
      sendGtmEvent({
        event: eventName,
        eventCategory: category || "RecAi",
        eventLabel: placementId || id,
        eventAction: "Carousel Impression",
      });
    }
    observer.disconnect();
  } else {
    sendGtmEvent({
      event: eventName,
      eventAction: "Carousel Impression",
    });
    observer.disconnect();
  }
};

export const getCarouselObserver = (
  carouselAnchor: HTMLElement,
  eventName: string
) => {
  const carouselObserver = new window.IntersectionObserver(
    (entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          setTimeout(() =>
            carouselView(carouselAnchor, carouselObserver, eventName)
          );
        }
      }),
    { threshold: [0.8] }
  );

  return carouselObserver;
};

const setCarouselView = (carouselAnchor: HTMLElement, eventName: string) => {
  const carouselObserver = getCarouselObserver(carouselAnchor, eventName);

  window.addEventListener("load", () => {
    carouselObserver.observe(carouselAnchor);
  });
};

const trackProductClick = async (itemNumber: string) => {
  const products = await createProducts([{ itemNumber }]);
  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        products,
      },
    },
  });
};

const setCarouselInteraction = (itemNumber: string, carouselId: string) => {
  const lookup = getCarouselInteraction();
  lookup[itemNumber.trim().toUpperCase()] = carouselId;
  session.setItem("carousel", JSON.stringify(lookup));
};

export const bindRecAiClickEvent = (id: string) => {
  const recAiCarousel = document.querySelector(id);
  if (recAiCarousel instanceof HTMLElement) {
    // This is the data-placement-id attribute on the recAi carousel
    const { placementId } = recAiCarousel.dataset;
    recAiCarousel
      .querySelectorAll("a.image,a.description,input[data-action=addToCart]")
      .forEach((link) => {
        link.addEventListener("click", ({ currentTarget: productLink }) => {
          if (!(productLink instanceof HTMLElement)) {
            throw new Error("product link must be instance of HTMLElement");
          }

          const parentItem = productLink.closest(".ag-item");
          if (!(parentItem instanceof HTMLElement)) {
            throw new Error("parent item must be instance of HTMLElement");
          }

          const { itemNumber } = parentItem.dataset;
          if ((placementId || id) && itemNumber) {
            setCarouselInteraction(
              itemNumber,
              placementId || id.replace("#", "")
            );
            if (productLink.nodeName === "A") {
              trackProductClick(itemNumber);
            }
          }
        });
      });
  }
};

export const bindYmanClickEvent = () => {
  const ymanCarousel = document.querySelector("div[data-feature-name=YMAN]");
  if (ymanCarousel instanceof HTMLElement) {
    ymanCarousel
      .querySelectorAll("a.image,a.description,input[data-action=addToCart]")
      .forEach((link) => {
        link.addEventListener("click", ({ currentTarget: productLink }) => {
          if (!(productLink instanceof HTMLElement)) {
            throw new Error("product link must be instance of HTMLElement");
          }

          const parentItem = productLink.closest(".ag-item");
          if (!(parentItem instanceof HTMLElement)) {
            throw new Error("parent item must be instance of HTMLElement");
          }

          const { itemNumber } = parentItem.dataset;
          if (itemNumber) {
            setCarouselInteraction(itemNumber, "yman");
            trackProductClick(itemNumber);
          }
        });
      });
  }
};

export const trackYmanCarouselViews = async () => {
  const carouselAnchor = document.querySelector("div[data-feature-name=YMAN]");
  if (carouselAnchor instanceof HTMLElement) {
    setCarouselView(carouselAnchor, "ymanEvent");
  }
};

export const trackRPFYCarouselViews = async () => {
  const observerAnchor = document.querySelector(
    "div[data-hypernova-key=RecommendedProductsForYou]"
  );
  if (observerAnchor instanceof HTMLElement) {
    setCarouselView(observerAnchor, "recAiEvent");
    const { getRecommendedProductsForYouCarousel } = await import(
      "./home/Carousel/Container"
    );

    await getRecommendedProductsForYouCarousel;

    const carouselAnchor = document.querySelector("#recommend-for-you");
    if (carouselAnchor instanceof HTMLElement) {
      sendGtmEvent({
        event: "recAiEvent",
        eventLabel: carouselAnchor.id,
        eventAction: "AI Impression",
      });
    }
  }
};

export const bindHomepageRecAiClickEvent = () => {
  const carouselAnchor = document.querySelector("#recommend-for-you");
  if (carouselAnchor instanceof HTMLElement) {
    carouselAnchor.querySelectorAll("a.image,a.description").forEach((link) => {
      link.addEventListener("click", ({ currentTarget: productLink }) => {
        if (!(productLink instanceof HTMLElement)) {
          throw new Error("product link must be instance of HTMLElement");
        }

        const parentItem = productLink.closest(".ag-item");
        if (!(parentItem instanceof HTMLElement)) {
          throw new Error("parent item must be instance of HTMLElement");
        }

        const { itemNumber } = parentItem.dataset;
        if (itemNumber) {
          setCarouselInteraction(itemNumber, carouselAnchor.id);
          if (productLink.nodeName === "A") {
            trackProductClick(itemNumber);
          }
        }
      });
    });
  }
};

export const sendATCWarrantyViewEvent = () => {
  sendGtmEvent({
    event: "WarrantyItemAddToCart",
  });
};

export const sendFacebookPixelServerEvent = async (
  eventName: string,
  eventId: string,
  userData: Record<string, string>,
  eventParams = {}
) => {
  await getDynamicData;
  const userDataThatShouldNotBeHashed = ["facebookClickId"];

  const combinedUserData = Object.keys(userData).reduce(
    (p, c) => ({
      ...p,
      [c]: userDataThatShouldNotBeHashed.includes(c)
        ? userData[c]
        : SHA256(userData[c] ?? "").toString(),
    }),
    {}
  );

  const fullUserData = {
    ...combinedUserData,
    client_ip_address: Store.getState().user.analyticsData.ip || "",
    client_user_agent: navigator.userAgent,
  };

  const data: FacebookServerEvent = {
    event_name: eventName,
    event_time: Math.floor(Date.now() / 1000),
    event_id: eventId,
    event_source_url: MarketingEventHelpers.removeEmailFromUrl(
      new URL(window.location.href)
    ),
    action_source: "website",
    user_data: fullUserData,
    custom_data: eventParams,
  };

  try {
    const result = await fetch(
      `https://${Environment.stashDomainWithEnvironment}/facebookConversion`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!result.ok) {
      await logException(
        new Error(
          `Facebook Conversion stash error: "${JSON.stringify(
            result.statusText
          )}"`
        )
      );
    }
  } catch (error) {
    await logException(
      new Error(`Facebook Conversion fetch error: "${getErrorMessage(error)}"`)
    );
  }
};

export const getFacebookPixelId = () => {
  return Environment.primary === "dev" ? "230878098552719" : "480797725416035";
};

export const getFacebookPixelUserData = () => {
  const { user } = Store.getState();
  return { ...user.facebookUserData, external_id: user.index || "0" };
};

export const getUserForPinterestEvent = () => {
  const { user } = Store.getState();
  return {
    ...user.facebookUserData,
    external_id: user.index || "0",
    ip: user.analyticsData.ip,
    user_agent: navigator.userAgent,
  };
};

export const getIsInternalUserStatus = () => {
  const { user } = Store.getState();
  return user.analyticsData.is_internal === "YES";
};

export const getRandomNumberString = () => {
  return `${Date.now()}${Math.round(Math.random() * 100000)}`;
};

export const sendMiniAdsCarouselImpressionEvent = () => {
  sendGtmEvent({
    event: "MiniHomepageAd Impression",
    eventCategory: "MiniHomepageAd",
    eventAction: "All Impressions",
  });
};

export const bindMiniAdsCarouselClickEvent = () => {
  const miniAdsCarousel = document.querySelector(".subfeature-grid");
  if (miniAdsCarousel instanceof HTMLElement) {
    const miniAdLinks = miniAdsCarousel.querySelectorAll("div>a");

    miniAdLinks.forEach(function (link) {
      link.addEventListener("click", function () {
        sendGtmEvent({
          event: "MiniHomepageAd Click",
          eventCategory: "MiniHomepageAd",
          eventAction: "Click",
        });
      });
    });
  }
};

export const bindMiniAdsCarouselActualImpressionEvent = () => {
  const miniAdsCarousel = document.querySelector(".subfeature-grid");

  const carouselObserver = new window.IntersectionObserver(
    (entries) =>
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          setTimeout(() => sendMiniAdsCarouselActualImpressionEvent());
          carouselObserver.disconnect();
        }
      }),
    { threshold: [0.8] }
  );

  if (miniAdsCarousel instanceof HTMLElement) {
    carouselObserver.observe(miniAdsCarousel);
  }
};

const sendMiniAdsCarouselActualImpressionEvent = () => {
  sendGtmEvent({
    event: "MiniHomepageAd Actual Impression",
    eventCategory: "MiniHomepageAd",
    eventAction: "Carousel Impression",
  });
};

export const bindMiniAdsCarouselTracking = () => {
  sendMiniAdsCarouselImpressionEvent();
  bindMiniAdsCarouselClickEvent();
  bindMiniAdsCarouselActualImpressionEvent();
};

export const getAbTestResult = async (featureName: string) => {
  const endpointUrl = `/api:edgecache/getabtestresult/?abfeaturename=${featureName}`;

  return fetch(endpointUrl).then((response) => {
    if (response.ok) {
      return response.json();
    }
    return null;
  });
};

const getHashedCustomerEmail = (email: string) => {
  if (email) {
    return `${SHA256(email.trim().toLowerCase())}`;
  }
  return "";
};

export const sendHashedCustomerEmailSignupEvent = (email: string) => {
  const hashedEmail = getHashedCustomerEmail(email);
  sendGtmEvent({
    event: "setHashedUserEmailSha256",
    hashedEmail,
  });
};

export const addToWishlistClick = async (itemNumber: string) => {
  const product = await createProducts([{ itemNumber }]);
  if (product[0]) {
    sendGtmEvent({
      event: "add_to_wishlist",
      ecommerce: {
        items: product,
        value: product[0].price,
        currency: "USD",
      },
    });
  }
};

export const addToCart = async (item: Item) => {
  const eventId = getRandomNumberString();
  const itemTotalCosts = (item?.price ?? 0) * (item?.quantity ?? 0);
  const recCarouselpriceFloat = parseFloat(
    itemTotalCosts ? itemTotalCosts.toString() : "0"
  );
  updateUserWithNewCartTotal(true, recCarouselpriceFloat);
  const priceOfItemAddedToCart = MarketingEventHelpers.createItemPriceWithTrailingZero(
    parseFloat(item.price ? item.price.toString() : "0")
  );
  const { user } = Store.getState();
  if (typeof fbq === "function") {
    fbq("trackSingle", getFacebookPixelId(), "AddToCart", {
      content_ids: [item.feedIdentifier],
      value: priceOfItemAddedToCart,
      currency: "USD",
      content_type: "product",
      eventID: eventId,
    });

    sendFacebookPixelServerEvent(
      "AddToCart",
      eventId,
      getFacebookPixelUserData(),
      {
        content_ids: [item.feedIdentifier],
        value: priceOfItemAddedToCart,
        currency: "USD",
        content_type: "product",
      }
    );
  }

  const isValidCartQuantity =
    item.quantity && Number.isInteger(item.quantity) && item.quantity <= 30000;

  if (isValidCartQuantity) {
    window.dataLayer.push({
      event: "addToCart",
      cart_total: user.analyticsData.cart_total,
      ecommerce: {
        currencyCode: "USD",
        add: {
          products: await createProducts([item]),
        },
      },
    });
  }
};

export const productRemoveFromCart = async (items: Item[]) => {
  const itemTotalCosts = items.map(
    (item) => (item?.price ?? 0) * (item?.quantity ?? 0)
  );
  const totalCost = itemTotalCosts.reduce((acc, curr) => acc + curr);
  updateUserWithNewCartTotal(false, totalCost);
  const product = await createProducts(items);
  if (product[0]) {
    sendGtmEvent({
      event: "productRemoveFromCart",
      ecommerce: {
        currencyCode: "USD",
        remove: {
          products: product,
        },
      },
    });
  }
};

export const updateUserWithNewCartTotal = async (
  isAddition: boolean,
  update: number
): Promise<void> => {
  await getDynamicData;
  const { user } = Store.getState();
  const startingCartTotal = user?.analyticsData?.cart_total ?? 0;

  user.analyticsData.cart_total = isAddition
    ? startingCartTotal + update
    : startingCartTotal - update;

  user.analyticsData.cart_total =
    Math.floor(user.analyticsData.cart_total * 100) / 100;

  if (user.analyticsData.cart_total < 0) {
    user.analyticsData.cart_total = 0;
  }

  Store.dispatch(updateUser(user));
};

const getAnalyticsDataFromStorage = () => {
  const storedData = session.getItem(analyticsStorageKey);
  if (storedData) {
    return JSON.parse(storedData);
  }
  return null;
};

const updateCustomDimensions = (analyticsData: AnalyticsData) => {
  const data = JSON.stringify(analyticsData);

  PushUserDataToDataLayer(analyticsData, true);
  session.setItem(analyticsStorageKey, data);
};

export const CheckForUserDataChangesAndUpdate = () => {
  const { analyticsData } = Store.getState().user;

  const data = getAnalyticsDataFromStorage();
  if (!data || !isEqual(data, analyticsData)) {
    updateCustomDimensions(analyticsData);
  } else {
    PushUserDataToDataLayer(analyticsData);
  }
};

const PushUserDataToDataLayer = (
  analyticsData: AnalyticsData,
  shouldFireCdsEvent = false
): void => {
  window.dataLayer.push(analyticsData);
  if (shouldFireCdsEvent) {
    sendGtmEvent({ event: "setCds" });
  }
};

export const updateVariationIds = async (
  abResponseOptions: UserAbFeatureOption[]
) => {
  await getDynamicData;

  const {
    user: {
      analyticsData: { variationIds },
    },
  } = Store.getState();

  const analyticsOptions = variationIds ? variationIds.split(",") : [];

  const newfeatureOptions = abResponseOptions
    .filter(
      (abResponseOption) =>
        !analyticsOptions?.find(
          (analyticsOption) =>
            analyticsOption.slice(0, analyticsOption.indexOf(":")) ===
            abResponseOption.featureName
        )
    )
    .map((x) => `${x.featureName}:${x.featureOption}`);

  if (newfeatureOptions.length) {
    const result = analyticsOptions?.concat(newfeatureOptions).join(",");

    sendGtmEvent({ event: "updateVariationId", updatedVariationId: result });
  }
};

export const pushAnalyticsDataToDataLayer = async () => {
  await getDynamicData;
  const { analyticsData } = Store.getState().user;
  window.dataLayer.push(analyticsData);
};

const sendProductImpressionEvent = async (itemNumbers: Item[]) => {
  const productData = await createProducts(itemNumbers);
  const allProducts = filterDataForImpressionEvent(productData);

  allProducts.forEach((viewedProduct) => {
    clearEcommerceInDataLayer();

    sendGtmEvent({
      event: "view_item_list",
      ecommerce: {
        items: [viewedProduct],
      },
    });
  });
};

export const bindTrackingForElements = (
  querySelectorForElementsToTrack: string,
  callback: IntersectionObserverCallback,
  percentageOfElementToTrack: number
) => {
  const elementsToTrack = document.querySelectorAll(
    querySelectorForElementsToTrack
  );

  if (elementsToTrack !== null) {
    const promoObserver = new window.IntersectionObserver(callback, {
      threshold: [percentageOfElementToTrack / 100],
    });

    Array.from(elementsToTrack).forEach((element) =>
      promoObserver.observe(element)
    );
  }
};

export const trackCarouselImpression = async (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const carouselName = entry.target.getAttribute("gtm-carousel-name");

      if (carouselName) {
        pushCarouselEventToDataLayer(carouselName);
        observer?.unobserve(entry.target);
      }
    }
  });
};

export const bindAddToCartButtonClicksForCarouselTracking = () => {
  const gtmCarousels = document?.querySelectorAll("[gtm-carousel-name]");

  for (const carousel of gtmCarousels) {
    const addToCartCarouselButtons: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarousel(
      carousel
    );

    for (const addToCartButton of addToCartCarouselButtons) {
      addToCartButton.addEventListener("click", async () => {
        const carouselName = getCarouselNameFromAddToCartButton(
          addToCartButton
        );

        const itemNumber =
          getProductItemNumberFromAddToCartButtonInGenericCarousel(
            addToCartButton
          ) ??
          getProductItemNumberFromAddToCartButtonInTopProductsCarousel(
            addToCartButton
          );

        const addToCartButtonCarouselPosition =
          [...addToCartCarouselButtons].indexOf(addToCartButton) + 1;

        const quantityAddedToCart = Number(
          getProductQuantityAddedToCartFromCarousel(addToCartButton)
        );

        if (
          quantityAddedToCartIsValid(quantityAddedToCart) &&
          carouselName &&
          addToCartButtonCarouselPosition &&
          itemNumber
        ) {
          const productAddedToCart = await formatProductDataForCarouselAddToCartClick(
            itemNumber,
            addToCartButtonCarouselPosition,
            quantityAddedToCart
          );

          pushCarouselAddToCartEventToDataLayer(
            carouselName,
            productAddedToCart
          );
        }
      });
    }
  }
};

const getCarouselNameFromAddToCartButton = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel
    ?.closest("[gtm-carousel-name]")
    ?.getAttribute("gtm-carousel-name");
};

const getProductItemNumberFromAddToCartButtonInGenericCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel.parentElement
    ?.getElementsByClassName("item_number")[0]
    ?.getAttribute("value");
};

const getProductItemNumberFromAddToCartButtonInTopProductsCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel?.parentElement?.parentElement
    ?.getElementsByClassName("item_number")[0]
    ?.getAttribute("value");
};

const getProductQuantityAddedToCartFromCarousel = (
  addToCartButtonOnCarousel: HTMLElement
) => {
  return addToCartButtonOnCarousel.parentElement
    ?.getElementsByClassName("quantity")[0]
    ?.getAttribute("value");
};

const getAddToCartButtonsFromCarouselByNameAttribute = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  return carousel?.querySelectorAll("[name=addToCartButton]");
};

const getAddToCartButtonsFromCarouselByDataAttribute = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  return carousel?.querySelectorAll("[data-action=addToCart]");
};

const getAddToCartButtonsFromCarousel = (
  carousel: Element
): NodeListOf<HTMLElement> => {
  const addToCartButtonsUsingNameAttribute: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarouselByNameAttribute(
    carousel
  );

  if (addToCartButtonsUsingNameAttribute.length > 0) {
    return addToCartButtonsUsingNameAttribute;
  }

  const addToCartButtonsUsingDataAttribute: NodeListOf<HTMLElement> = getAddToCartButtonsFromCarouselByDataAttribute(
    carousel
  );

  return addToCartButtonsUsingDataAttribute;
};

export const getDataForProductsInRow = async (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  const itemNumbers: Item[] = [];

  entries.forEach(async (entry) => {
    if (entry.isIntersecting === true) {
      const itemNumber = entry.target.getAttribute("gtm-tracking-number");

      if (itemNumber) {
        itemNumbers.push({ itemNumber });
      }

      observer?.unobserve(entry.target);
    }
  });

  if (itemNumbers.length > 0) {
    await sendProductImpressionEvent(itemNumbers);
  }
};

export const markProductViewed = async (itemNumber: string) => {
  await sendProductImpressionEvent([{ itemNumber }]);
};

const filterDataForImpressionEvent = (products: Product[]) => {
  const productsData: Record<string, unknown>[] = [];

  products.forEach((product) => {
    productsData.push({
      item_id: product?.id,
      item_name: product?.name,
      [itemNumberDimension]: product?.itemNumber?.toUpperCase().trim(),
      item_brand: product?.brand,
      item_category: product?.item_category,
      item_category2: product?.item_category2,
      item_category3: product?.item_category3,
      item_category4: product?.item_category4,
      item_category5: product?.item_category5,
      item_list_name: "Product Impression",
      price: product?.price,
      quantity: 1,
    });
  });

  return productsData;
};

export const checkIfOrderNumberWasPreviouslyTrackedInGA = (
  orderNumber: string
): boolean => {
  const previouslyStoredOrderNumbers =
    window.localStorage.getItem("orderNumbers")?.split(",") ?? [];

  if (previouslyStoredOrderNumbers.includes(orderNumber)) {
    return true;
  }

  storeOrderNumbers(previouslyStoredOrderNumbers, orderNumber);
  return false;
};

const storeOrderNumbers = (
  previouslyStoredOrderNumbers: string[],
  mostRecentOrderNumber: string
): void => {
  previouslyStoredOrderNumbers.push(mostRecentOrderNumber);
  let orderNumbersToStore = previouslyStoredOrderNumbers;

  const maxAmountOfOrderNumbersToStore = 15;

  if (previouslyStoredOrderNumbers.length > maxAmountOfOrderNumbersToStore) {
    orderNumbersToStore = [mostRecentOrderNumber];
  }

  window.localStorage.setItem("orderNumbers", orderNumbersToStore.toString());
};

const pushCarouselEventToDataLayer = (carouselName: string) => {
  sendGtmEvent({
    event: "carouselImpression",
    carouselName,
    carouselModel: checkIfCarouselIsRecAi(carouselName),
  });
};

const pushCarouselAddToCartEventToDataLayer = (
  carouselName: string,
  productAddedToCartFromCarousel: Record<string, unknown>[]
) => {
  sendGtmEvent({
    event: "carouselAddToCartClick",
    carouselName,
    carouselModel: checkIfCarouselIsRecAi(carouselName),
    ecommerce: {
      productAddedToCartFromCarousel,
    },
  });
};

const formatProductDataForCarouselAddToCartClick = async (
  itemNumber: string,
  addToCartButtonPositionInCarousel: number,
  quantityAddedToCart: number
) => {
  const productAddedToCart = await createProducts([{ itemNumber }]);
  const productAddedToCartData: Record<string, unknown>[] = [];

  productAddedToCart.forEach((product) => {
    productAddedToCartData.push({
      item_id: product?.id,
      item_name: product?.name,
      [itemNumberDimension]: product?.itemNumber?.toUpperCase().trim(),
      item_brand: product?.brand,
      item_category: product?.item_category,
      item_category2: product?.item_category2,
      item_category3: product?.item_category3,
      item_category4: product?.item_category4,
      item_category5: product?.item_category5,
      price: product?.price,
      quantity: quantityAddedToCart,
      position: addToCartButtonPositionInCarousel,
    });
  });

  return productAddedToCartData;
};

const quantityAddedToCartIsValid = (quantityAddedToCart: number) => {
  const maxAmountOfItemsAllowedByGoogleAnalytics = 30_000;
  return (
    quantityAddedToCart &&
    Number.isInteger(quantityAddedToCart) &&
    quantityAddedToCart <= maxAmountOfItemsAllowedByGoogleAnalytics
  );
};

const checkIfCarouselIsRecAi = (carouselName: string) => {
  const RecAiCarouselNames = [
    "Recommended For You",
    "Best Selling Products",
    "Recommended Products",
    "Items You Might Like",
  ];

  if (RecAiCarouselNames.includes(carouselName)) {
    return "RecAi";
  }
  return "Manual";
};

export const getSelectedOptionAndSendGtmEvent = (
  selectedValue: string | number,
  eventName: string,
  rootNode: HTMLElement | Document = document
) => {
  const selectedText =
    rootNode.querySelector(
      `[value='${selectedValue.toString().replaceAll("'", "\\'")}']`
    )?.textContent ?? "";
  clearGa4EventInDataLayer();
  sendGtmEvent({
    event: "ga4_event",
    eventName,
    eventAction: eventName,
    eventLabel: selectedText,
  });
};
