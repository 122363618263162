import React from "react";
import { cls } from "cls";
import { connect } from "react-redux";
import {
  ChatState,
  RootState,
  UserState,
} from "~/common/Store/models/RootReadonly";
import AlertBannerLeftContainer from "../AlertBannerLeftContainer";
import AlertBannerRightContainer from "../AlertBannerRightContainer";
import AdCampaignData from "~/common/PlusEssentialsAd/AdCampaignData.json";
import { PageCreatorBanner } from "../PageCreatorBanner";
import PlusEssentialsAdWithCampaignData from "./PlusEssentialsAdWithCampaignData";
import PlusEssentialsAd from "./PlusEssentialsAd";

interface BannerProps {
  user: UserState;
  mounted: boolean;
  chat: ChatState;
  checkout: boolean;
  isCustomerServiceMessageAvailable: boolean;
  adTypeId: number;
  pageCreatorBanner: PageCreatorBanner | null;
  eventDayBannerVisible: boolean;
}

const PlusEssentialsBanner = ({
  user,
  mounted,
  chat,
  checkout,
  isCustomerServiceMessageAvailable,
  adTypeId,
  pageCreatorBanner,
  eventDayBannerVisible,
}: BannerProps) => {
  const adCampaignData = AdCampaignData.find((x) => x.id === adTypeId);

  const phaseCutoffValue = 13;
  const showPlusEssentialsAdPhase1And2Version =
    adTypeId > 0 && adTypeId < phaseCutoffValue;
  const showPlusEssentialsAdPhase3Version = adTypeId === phaseCutoffValue;
  const showPlusEssentialsAdPhase4Version = adTypeId > phaseCutoffValue;

  return (
    <div
      data-testid="plus-essentials-banner"
      className="bg-[#06142B] border-0 flex items-center justify-center lt:block box-border min-h-8"
    >
      <div className={cls("lt:mx-auto", "py-1.5", "container")}>
        <div
          className={cls(
            "flex",
            "items-center",
            "justify-center",
            "lt:justify-between"
          )}
        >
          <AlertBannerLeftContainer
            chat={chat}
            eventDayBannerVisible={eventDayBannerVisible}
            mounted={mounted}
            isCustomerServiceMessageAvailable={
              isCustomerServiceMessageAvailable
            }
            isEventDayDataLoading={false}
            checkout={false}
            isPlusEssentials
            pageCreatorTextColor={pageCreatorBanner?.textColor ?? ""}
          />
          {!checkout && showPlusEssentialsAdPhase3Version ? (
            <PlusEssentialsAd />
          ) : null}
          {checkout || !adCampaignData ? null : (
            <>
              {(showPlusEssentialsAdPhase1And2Version ||
                showPlusEssentialsAdPhase4Version) && (
                <PlusEssentialsAdWithCampaignData
                  adCampaignData={adCampaignData}
                  modifyNameAndStyling={showPlusEssentialsAdPhase4Version}
                />
              )}
            </>
          )}
          {!checkout &&
            (isCustomerServiceMessageAvailable ? null : (
              <AlertBannerRightContainer
                user={user}
                isCustomerServiceMessageAvailable={
                  isCustomerServiceMessageAvailable
                }
                isPlusEssentials
                eventDayBannerVisible={eventDayBannerVisible}
                mounted={mounted}
                isEventDayDataLoading={false}
                pageCreatorBanner={pageCreatorBanner}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState, ownProps: BannerProps) => {
  if (!ownProps.mounted) {
    return {};
  }

  return state;
};

export default connect(mapStateToProps)(PlusEssentialsBanner);
