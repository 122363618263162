import React from "react";

interface DropDownProps {
  children: React.ReactNode;
  active: boolean;
  menuRef: React.RefObject<HTMLUListElement>;
  isLoggedIn?: boolean;
  isCheckout?: boolean;
}

const MenuDropdown = ({
  children,
  active,
  menuRef,
  isLoggedIn = false,
  isCheckout = false,
}: DropDownProps) => {
  return (
    <ul
      ref={menuRef}
      className={`bg-white rounded list-none ml-0 mt-1 absolute right-0 z-50 shadow-xl min-w-[340px] bg-clip-padding ${
        active ? "block" : "hidden"
      } ${
        isLoggedIn
          ? `min-w-[340px] ${!isCheckout && "lt:left-[-73px] xxl:left-0"}`
          : "min-w-[158px] py-1-1/2 px-2 left-0"
      }`}
    >
      {children}
    </ul>
  );
};

export default MenuDropdown;
