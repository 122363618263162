import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.16667C0 0.522334 0.543654 0 1.21429 0H15.7857C16.4563 0 17 0.522334 17 1.16667C17 1.811 16.4563 2.33333 15.7857 2.33333H1.21429C0.543654 2.33333 0 1.811 0 1.16667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 6.35567 0.543654 5.83333 1.21429 5.83333H10.9286C11.5992 5.83333 12.1429 6.35567 12.1429 7C12.1429 7.64433 11.5992 8.16667 10.9286 8.16667H1.21429C0.543654 8.16667 0 7.64433 0 7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.8333C0 12.189 0.543654 11.6667 1.21429 11.6667H15.7857C16.4563 11.6667 17 12.189 17 12.8333C17 13.4777 16.4563 14 15.7857 14H1.21429C0.543654 14 0 13.4777 0 12.8333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 6.35567 0.543654 5.83334 1.21429 5.83334H10.9286C11.5992 5.83334 12.1429 6.35567 12.1429 7C12.1429 7.64433 11.5992 8.16667 10.9286 8.16667H1.21429C0.543654 8.16667 0 7.64433 0 7Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.16667C0 0.522334 0.543654 0 1.21429 0H15.7857C16.4563 0 17 0.522334 17 1.16667C17 1.811 16.4563 2.33333 15.7857 2.33333H1.21429C0.543654 2.33333 0 1.811 0 1.16667Z"
        fill="white"
      />
    </svg>
  );
};

export default MenuIcon;
