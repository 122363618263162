import React from "react";
import ct from "class-types.macro";

interface Props {
  pendingOrders?: number;
}

const PendingOrders = ({ pendingOrders }: Props) => (
  <span className={ct("flex", "items-center")} data-testid="pending-orders">
    Pending Orders
    <span
      className={ct(
        "whitespace-nowrap",
        "bg-green-500",
        "rounded-full",
        "inline-block",
        "font-bold",
        "text-2xs",
        "leading-normal",
        "ml-2",
        "text-white",
        "align-top"
      )}
      style={{ padding: "1px 7px" }}
    >
      {pendingOrders}
    </span>
  </span>
);

export default PendingOrders;
