import React, { MouseEvent } from "react";
import MenuIcon from "./Icons/MenuIcon";
import CloseIcon from "./Icons/CloseIcon";

interface Props {
  setMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileNavOpen: boolean;
}

const MenuButton = ({ setMobileNavOpen, mobileNavOpen }: Props) => {
  const toggleMobileNav = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMobileNavOpen((prev) => !prev);
    document.documentElement.classList.toggle("mobile-open");
    document.body.classList.toggle("open");
  };

  return (
    <button
      onClick={(e: MouseEvent<HTMLButtonElement>) => toggleMobileNav(e)}
      aria-label="primary navigation"
      aria-expanded={mobileNavOpen}
      className="flex items-center lt:hidden bg-green-500 border-0"
      data-testid="menu-button"
      type="button"
    >
      {mobileNavOpen ? <CloseIcon /> : <MenuIcon />}
      <span className="font-semibold pl-1.5 text-white text-xl">Menu</span>
    </button>
  );
};

export default MenuButton;
