import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { cls } from "cls";
import Icon from "~/common/Icons/Critical";
import encodeAndSanitizeSearchQuery from "~/common/helpers/encodeAndSanitizeSearchQuery";
import SearchIcon from "./Icons/SearchIcon";

const ImageSearchMenuButton = lazy(() => import("./ImageSearchMenuButton"));

interface Props {
  imageSearchFeatureFlag?: boolean;
}

const Search = ({ imageSearchFeatureFlag }: Props) => {
  const [touchevents, setTouchevents] = useState(false);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  let cancelTouchEvents = false;

  const searchInput = useRef<HTMLInputElement>(null);

  const form = useRef<HTMLFormElement>(null);

  const submitButton = useRef<HTMLButtonElement>(null);

  const initTouchevents = async () => {
    if (cancelTouchEvents) return;

    const hasTouchEvents = Boolean(
      "ontouchstart" in window || navigator.maxTouchPoints
    );
    setTouchevents(hasTouchEvents);
  };

  const initSearchAutoComplete = async () => {
    const { default: SearchAutoComplete } = await import(
      "~/common/SearchAutocomplete"
    );
    const { current } = searchInput;
    if (!current || !submitButton.current) return;

    new SearchAutoComplete(current, submitButton.current);

    if (form.current) {
      form.current.addEventListener("submit", () => {
        import("storage-factory").then(({ storageFactory }) => {
          const storage = storageFactory(() => window.localStorage);

          storage.setItem("HeaderBannerSearchValue", current.value);
        });
      });
    }

    if (current.value) return;

    if (window.location.pathname.includes("/search/")) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const pageName = window.location.pathname.split("/").pop() as string;
      const searchTerm = decodeURIComponent(
        pageName
          .replace(/\.\w+$/, "")
          .replace(/-+/g, (match) => (match.length > 1 ? "-" : " "))
      );
      const { storageFactory } = await import("storage-factory");

      const storage = storageFactory(() => window.localStorage);
      const headerSearchValue = storage.getItem("HeaderBannerSearchValue");

      if (searchTerm !== headerSearchValue) {
        current.value = searchTerm;
        setSearchValue(searchTerm);
      } else {
        current.value = headerSearchValue;
        setSearchValue(headerSearchValue);
      }
    }
  };

  const clickSearch = (event: { preventDefault: () => void }) => {
    let term = "";
    event.preventDefault();

    if (searchInput.current != null) {
      term = encodeAndSanitizeSearchQuery(searchInput.current.value);
    }

    if (term.length > 0) {
      window.location.href = `/search/${term}.html`;
    } else {
      window.location.href = "/search.html";
    }
  };

  useEffect(() => {
    initTouchevents();

    initSearchAutoComplete();
    return function cleanup() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancelTouchEvents = true;
    };
  }, []);

  function handleCancel() {
    if (form.current && searchInput.current) {
      form.current.reset();
      setSearchValue("");
      searchInput.current.focus();
    }
  }

  const placeholderText = "What are you looking for?";

  const hasSearchValue = searchValue.length > 0;

  return (
    <div
      className={cls(
        "flex",
        "flex-initial",
        "relative",
        "w-full",
        "mx-auto",
        "mb-0",
        "h-10",
        touchevents ? " scanner-app-ready" : ""
      )}
      data-testid="search-input"
    >
      <div
        id="banner-search-group"
        className={cls("box-border", "pr-0", "relative", "w-full")}
      >
        <form
          ref={form}
          method="get"
          action="/search.html"
          className={cls("flex", "w-full")}
          id="searchForm"
          role="search"
        >
          <div className={cls("flex", "flex-nowrap", "h-10", "w-full")}>
            <div
              className={cls(
                "bg-white",
                "focus:border-blue-300",
                "rounded",
                "border",
                "border-r-0",
                "border-solid",
                "border-gray-400",
                "box-border",
                "flex",
                "flex-nowrap",
                "items-center",
                "flex-auto",
                "text-sm",
                "leading-4",
                "mb-0",
                "focus:outline-none",
                "text-gray-800",
                "align-middle",
                "w-full",
                "transition",
                "ease-linear",
                "duration-200",
                "lt:rounded-r-none",
                touchevents ? cls("pl-0.5") : "",
                searchFocus ? cls("blue-border-80", "blue-shadow-inset") : ""
              )}
            >
              <input
                ref={searchInput}
                type="text"
                name="searchval"
                id="searchval"
                data-testid="searchval"
                autoComplete="off"
                onChange={(e) => setSearchValue(e.target.value)}
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                className={cls(
                  "border-0",
                  "box-border",
                  "text-base",
                  "mb-0",
                  "focus:outline-none",
                  "py-3",
                  "focus:placeholder-transparent",
                  "w-full",
                  "h-full",
                  "bg-transparent",
                  "typeahead",
                  "banner-search-input",
                  "placeholder:text-gray-600",
                  "shadow-none",
                  "max-h-[38px]",
                  touchevents ? "pl-[9px]" : "pl-3"
                )}
                aria-label="Search: type a search term, and navigate through results with up and down arrows"
                placeholder={placeholderText}
              />
              {hasSearchValue && (
                <button
                  type="button"
                  onClick={handleCancel}
                  aria-label="Clear Search"
                  className={cls(
                    "bg-white",
                    "border-0",
                    "cursor-pointer",
                    "m-0",
                    "py-0",
                    "px-3",
                    "pt-1",
                    "text-gray-400",
                    "hover:text-gray-600",
                    "focus:text-gray-600"
                  )}
                >
                  <Icon
                    name="cancel"
                    className={cls("bg-white", "h-4", "fill-current", "w-4")}
                  />
                </button>
              )}
              {!hasSearchValue && touchevents && (
                <Suspense>
                  <ImageSearchMenuButton
                    imageSearchFeatureFlag={imageSearchFeatureFlag}
                  />
                </Suspense>
              )}
            </div>
            <button
              value="Search"
              ref={submitButton}
              onClick={clickSearch}
              className={cls(
                "text-white",
                "hidden",
                "rounded-r",
                "border-0",
                "box-border",
                "text-sm",
                "py-2.5",
                "px-4-1/2",
                "lt:flex",
                "lt:items-center",
                "cursor-pointer",
                "bg-blue-700",
                "lt:hover:bg-blue-800",
                "tracking-[.02em]"
              )}
              type="submit"
            >
              <span className="sr-only">Search WebstaurantStore</span>
              <SearchIcon />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;
