import React from "react";
import { cls } from "cls";
import connectUser, { User } from "~/common/Store/connectUserAndChat";
import useLoggedInAs from "~/common/hooks/useLoggedInAs";

interface LoggedInAsBannerProps {
  user: User;
}

function LoggedInAsBanner({ user }: LoggedInAsBannerProps) {
  const showLoggedInAsBanner = useLoggedInAs(user);

  if (!showLoggedInAsBanner) {
    return null;
  }

  return (
    <div
      className={cls(
        "fixed",
        "bottom-[10px]",
        "right-0",
        "left-0",
        "flex",
        "container",
        "px-[10px]",
        "z-1000"
      )}
    >
      <div
        className={cls(
          "flex",
          "w-full",
          "lg:w-auto",
          "lg:mx-auto",
          "drop-shadow-lg",
          "bg-[#c53030]",
          "py-1",
          "px-2",
          "sm:py-2",
          "sm:px-4",
          "md:py-4",
          "md:px-8",
          "rounded"
        )}
      >
        <div className={cls("flex")}>
          <svg
            className="hidden sm:inline fill-white h-min w-8 mt-0.5 sm:h-5 sm:w-5 sm:mt-0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z" />
          </svg>
          <div
            className={cls(
              "text-[#fff5f5]",
              "flex",
              "flex-col",
              "md:flex-row",
              "md:items-center",
              "items-start",
              "justify-center",
              "leading-4",
              "ml-2",
              "mr-8",
              "text-sm"
            )}
          >
            <span className="font-semibold md:mr-0.5">
              You&apos;re Logged in as {user.fullName}
            </span>
            <span className="font-normal">
              <span className="sm:hidden md:inline">&mdash;</span>{" "}
              <span className="sm:capitalize md:normal-case">c</span>hanges made
              will be visible to customers
            </span>
          </div>
        </div>
        <span className="flex items-center justify-center ml-auto">
          <a
            className={cls(
              "w-full",
              "text-center",
              "bg-white",
              "active:bg-[#eef0ed]",
              "focus:underline",
              "rounded-sm",
              "text-gray-800",
              "cursor-pointer",
              "inline",
              "text-sm",
              "font-semibold",
              "px-2",
              "hover:bg-gray-100"
            )}
            href="/myaccount/?logout=Y"
            type="button"
          >
            Logout
            <span className="sr-only">from your account</span>
          </a>
        </span>
      </div>
    </div>
  );
}

export default connectUser(LoggedInAsBanner);
