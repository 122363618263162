import React from "react";

interface RewardsBadgeProps {
  classNames?: ClassTypes;
}

const RewardsBadge = ({ classNames }: RewardsBadgeProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames}
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="26" height="17.4634" rx="1.5" fill="black" />
      <path
        d="M14.6484 5.49665C14.4445 5.59083 14.2229 5.63938 13.9986 5.63898C13.4732 5.63898 13.1616 5.33399 13.1616 4.87652C13.1614 4.73931 13.1888 4.60349 13.2419 4.47724C13.2952 4.351 13.373 4.23695 13.471 4.14196C13.569 4.04697 13.6849 3.97301 13.8119 3.92454C13.9388 3.87607 14.0742 3.85409 14.2098 3.85992C14.5868 3.85992 14.847 4.02893 14.847 4.34535C14.847 4.6821 14.5868 4.90448 13.5901 4.90448C13.599 5.13067 13.7862 5.23996 14.0602 5.23996C14.2605 5.23939 14.4583 5.19608 14.6409 5.11288L14.6484 5.49665ZM14.4159 4.38347C14.4159 4.28943 14.3116 4.24241 14.1646 4.24241C13.883 4.24241 13.6995 4.38474 13.6442 4.57916C14.2714 4.57535 14.4096 4.48259 14.4096 4.38347H14.4159Z"
        fill="white"
      />
      <path
        d="M16.6412 5.13835L17.1628 3.89428H17.6316L16.8775 5.60344H16.327L16.1925 4.56651L15.7325 5.60344H15.1832L14.962 3.89428H15.4032L15.5351 5.13835L16.0819 3.92097H16.474L16.6412 5.13835Z"
        fill="white"
      />
      <path
        d="M17.4426 4.88548C17.4383 4.75476 17.4594 4.62445 17.505 4.50203C17.5506 4.37961 17.6196 4.26749 17.7081 4.1721C17.7966 4.07671 17.9029 3.99992 18.0208 3.94614C18.1387 3.89236 18.266 3.86265 18.3953 3.85871C18.4928 3.85016 18.5907 3.86802 18.6791 3.91046C18.7675 3.9529 18.8431 4.01838 18.8981 4.10015L18.9383 3.89429H19.3707L19.1042 5.60346H18.6706L18.6932 5.39632C18.6198 5.47611 18.5303 5.53906 18.4308 5.58094C18.3313 5.6228 18.224 5.64261 18.1163 5.63904C17.7129 5.63904 17.4426 5.32516 17.4426 4.88548ZM18.4192 4.26535C18.3484 4.26616 18.2784 4.28125 18.2134 4.30973C18.1484 4.33821 18.0896 4.37951 18.0406 4.43122C17.9915 4.48293 17.9532 4.544 17.9278 4.61087C17.9024 4.67775 17.8904 4.74907 17.8926 4.82067C17.8886 4.87313 17.8954 4.92587 17.9125 4.97555C17.9297 5.02523 17.9568 5.07078 17.9922 5.10934C18.0277 5.14789 18.0706 5.17862 18.1184 5.19958C18.1661 5.22054 18.2176 5.23128 18.2696 5.23113C18.4137 5.22812 18.5509 5.16768 18.6512 5.06297C18.7514 4.95825 18.8067 4.81774 18.8051 4.67199C18.8086 4.61908 18.8012 4.566 18.7832 4.51616C18.7653 4.46631 18.7373 4.4208 18.701 4.38253C18.6646 4.34426 18.6208 4.31407 18.5723 4.29389C18.5238 4.27372 18.4716 4.26399 18.4192 4.26535Z"
        fill="white"
      />
      <path
        d="M19.9959 4.12677C20.0454 4.04465 20.115 3.97682 20.198 3.92986C20.281 3.88288 20.3746 3.85836 20.4697 3.85864C20.5413 3.85955 20.6122 3.87243 20.6796 3.89676L20.5803 4.30467C20.5169 4.28009 20.4497 4.26718 20.3817 4.26655C20.1857 4.26655 19.9644 4.37456 19.9079 4.72656L19.7709 5.60338H19.3285L19.5962 3.89422H20.0248L19.9959 4.12677Z"
        fill="white"
      />
      <path
        d="M20.4846 4.89055C20.4846 4.30219 20.9107 3.85869 21.4412 3.85869C21.5386 3.85028 21.6365 3.86821 21.7248 3.91063C21.8132 3.95305 21.8888 4.01846 21.9439 4.10014L22.0997 3.09497H22.5346L22.1425 5.60344H21.7076L21.7265 5.42172C21.5718 5.56785 21.3662 5.646 21.1546 5.63902C20.7473 5.63902 20.4846 5.32514 20.4846 4.89055ZM21.4676 4.26788C21.3965 4.26752 21.326 4.28158 21.2604 4.30923C21.1947 4.33689 21.1353 4.37756 21.0854 4.42887C21.0356 4.48017 20.9965 4.54106 20.9704 4.60791C20.9443 4.67477 20.9317 4.74625 20.9334 4.81811C20.9294 4.87058 20.9362 4.92331 20.9533 4.97299C20.9705 5.02267 20.9976 5.06822 21.0331 5.10678C21.0684 5.14534 21.1114 5.17606 21.1591 5.19702C21.2069 5.21798 21.2584 5.22872 21.3105 5.22857C21.4534 5.22491 21.5891 5.16467 21.6885 5.06081C21.788 4.95696 21.8431 4.81778 21.8421 4.67325C21.8457 4.6212 21.8387 4.56896 21.8215 4.51975C21.8044 4.47054 21.7774 4.42543 21.7423 4.38719C21.7072 4.34895 21.6647 4.3184 21.6174 4.29744C21.5702 4.27648 21.5191 4.26556 21.4676 4.26534V4.26788Z"
        fill="white"
      />
      <path
        d="M23.3113 4.59323C23.5904 4.64914 23.7324 4.78003 23.7324 5.01893C23.7324 5.41668 23.4081 5.63906 22.9406 5.63906C22.7271 5.6452 22.5151 5.60172 22.3209 5.51198L22.4265 5.13838C22.5963 5.21349 22.7791 5.25408 22.9644 5.25783C23.2007 5.25783 23.2962 5.20446 23.2962 5.09009C23.2962 5.01385 23.2284 4.97064 23.0587 4.93887L22.8639 4.90075C22.5949 4.85119 22.4617 4.70633 22.4617 4.47759C22.4617 4.10653 22.8011 3.85619 23.2799 3.85619C23.4668 3.85142 23.6525 3.88785 23.8241 3.96293L23.7286 4.33526C23.5743 4.27252 23.4098 4.23975 23.2435 4.23869C23.0084 4.23869 22.894 4.29968 22.894 4.40897C22.894 4.48267 22.9644 4.52334 23.1454 4.55765L23.3113 4.59323Z"
        fill="white"
      />
      <path
        d="M12.4941 4.83589C12.694 4.77782 12.8695 4.65545 12.9943 4.48734C13.119 4.31924 13.1862 4.11458 13.1855 3.90442C13.1855 3.44822 12.8737 3.18517 12.4501 3.18517H11.7639C11.598 3.36308 11.4321 3.55751 11.2712 3.76591L10.9834 5.60342H11.4509L11.5616 4.89053H11.9977C12.1736 5.07987 12.3031 5.639 12.8172 5.639C12.8957 5.64132 12.9741 5.63146 13.0497 5.60977L13.1063 5.24888C13.0709 5.25783 13.0346 5.2621 12.9982 5.26159C12.7405 5.26159 12.6374 5.02777 12.4941 4.83589ZM12.1938 4.47626H11.6319L11.7689 3.60198H12.3358C12.3847 3.59763 12.4339 3.60406 12.4801 3.62082C12.5264 3.63759 12.5684 3.66428 12.6034 3.69908C12.6384 3.73387 12.6656 3.77593 12.6829 3.82235C12.7003 3.86877 12.7075 3.91845 12.7041 3.96796C12.7055 4.03575 12.6932 4.10312 12.6679 4.16594C12.6427 4.22876 12.605 4.28571 12.5573 4.3333C12.5095 4.38088 12.4526 4.41811 12.3901 4.44269C12.3276 4.46728 12.2608 4.4787 12.1938 4.47626Z"
        fill="white"
      />
      <path
        d="M10.5907 4.05376C10.3362 4.31831 10.1123 4.60963 9.92306 4.92241C9.91016 4.90617 9.89725 4.88178 9.88345 4.86282C9.75544 4.69306 9.53993 4.41947 9.30969 4.28131C9.27058 4.25607 9.22482 4.24258 9.178 4.24248C9.14572 4.24306 9.11409 4.25143 9.0859 4.26686C9.05317 4.28422 9.02701 4.31145 9.0113 4.34452C8.99721 4.38576 8.99627 4.43024 9.00861 4.47202C9.02095 4.5138 9.04599 4.5509 9.08037 4.57839C9.30827 4.8427 9.49847 5.13613 9.64582 5.45065L9.68085 5.51928C9.70691 5.56779 9.74537 5.60879 9.79256 5.63825C9.8397 5.66771 9.89391 5.68459 9.94977 5.68723H9.97279C10.0296 5.67745 10.0833 5.65438 10.1292 5.61996C10.175 5.58553 10.2117 5.54077 10.2362 5.48948L10.2555 5.45607C10.7095 4.57297 11.3017 3.87047 11.729 3.52553C11.8607 3.41988 11.9197 3.37293 11.9243 3.37022C11.9573 3.34514 11.9881 3.31735 12.0163 3.28715C12.0415 3.26459 12.0591 3.23508 12.0668 3.20253C12.0744 3.16997 12.0719 3.13589 12.0594 3.10479C12.0469 3.07369 12.0252 3.04703 11.9969 3.02832C11.9687 3.00961 11.9353 2.99974 11.9012 3.00001C11.8445 3.00309 11.7901 3.02258 11.7447 3.05599L11.7346 3.06231L11.7152 3.07405C11.3275 3.32598 10.8928 3.74495 10.5907 4.05376Z"
        fill="#00AEEF"
      />
      <path
        d="M7.10685 9H3.34868C3.08923 9 2.87891 9.21032 2.87891 9.46977V11.8186C2.87891 12.0781 3.08923 12.2884 3.34868 12.2884H7.10685C7.3663 12.2884 7.57662 12.0781 7.57662 11.8186V9.46977C7.57662 9.21032 7.3663 9 7.10685 9Z"
        fill="#5F5D60"
      />
      <path
        d="M13.0964 14H2.99635C2.93149 14 2.87891 14.0526 2.87891 14.1174V14.8221C2.87891 14.887 2.93149 14.9395 2.99635 14.9395H13.0964C13.1613 14.9395 13.2138 14.887 13.2138 14.8221V14.1174C13.2138 14.0526 13.1613 14 13.0964 14Z"
        fill="#5F5D60"
      />
      <path
        d="M18.9845 15.1635H18.2214L18.6987 12.2121H19.4618L18.9845 15.1635Z"
        fill="white"
      />
      <path
        d="M21.7508 12.2844C21.6003 12.2246 21.3615 12.1587 21.0664 12.1587C20.3128 12.1587 19.7821 12.5605 19.7789 13.1351C19.7726 13.559 20.1588 13.7944 20.4476 13.9358C20.7429 14.0802 20.8433 14.1744 20.8433 14.3032C20.8403 14.5009 20.6047 14.592 20.385 14.592C20.0803 14.592 19.917 14.545 19.669 14.435L19.5685 14.3879L19.4617 15.0504C19.6407 15.1319 19.9704 15.2043 20.3128 15.2075C21.1135 15.2075 21.6348 14.8118 21.6409 14.1995C21.644 13.8636 21.4401 13.6062 21.0004 13.3958C20.7335 13.2608 20.57 13.1697 20.57 13.0316C20.5732 12.9059 20.7083 12.7773 21.0096 12.7773C21.2576 12.771 21.44 12.8306 21.578 12.8903L21.647 12.9216L21.7508 12.2844Z"
        fill="white"
      />
      <path
        d="M22.7651 14.1179C22.828 13.9484 23.0698 13.2921 23.0698 13.2921C23.0666 13.2985 23.1325 13.1195 23.1702 13.0096L23.2235 13.2639C23.2235 13.2639 23.368 13.9704 23.3994 14.1179C23.2801 14.1179 22.9158 14.1179 22.7651 14.1179ZM23.7071 12.2121H23.1168C22.9348 12.2121 22.7965 12.2655 22.718 12.457L21.5844 15.1634H22.3852C22.3852 15.1634 22.517 14.7991 22.5453 14.7207C22.6331 14.7207 23.4121 14.7207 23.5251 14.7207C23.547 14.8243 23.6161 15.1634 23.6161 15.1634H24.3227L23.7071 12.2121Z"
        fill="white"
      />
      <path
        d="M17.584 12.2121L16.8367 14.2246L16.755 13.8165C16.6168 13.3455 16.1835 12.8338 15.6999 12.5794L16.3845 15.1603H17.1914L18.3909 12.2121H17.584Z"
        fill="white"
      />
      <path
        d="M16.1426 12.2121H14.9149L14.9023 12.2718C15.8601 12.5167 16.4943 13.107 16.755 13.8165L16.4881 12.4602C16.4441 12.2718 16.3091 12.2184 16.1426 12.2121Z"
        fill="white"
      />
      <rect
        x="0.5"
        y="0.5"
        width="26"
        height="17.4634"
        rx="1.5"
        stroke="white"
      />
    </svg>
  );
};

export default RewardsBadge;
