export default function encodeAndSanitizeSearchQuery(value: string) {
  let term = value;

  term = term
    .replace(/^( ){1,}|( ){1,}$|\n|\r|\t|<|>/g, "")
    .toLowerCase()
    .replace(/\u2013|\u2014/g, "-");
  term = encodeURIComponent(term)
    .replace(/(-){1}/g, "--")
    .replace(/(%20){1,}/g, "-")
    .replace(/'/g, "%27");

  return term;
}
