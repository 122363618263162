import { cls } from "cls";
import React from "react";

const SearchIcon = () => {
  return (
    <svg
      aria-hidden="true"
      className={cls("fill-white")}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2745 13.1548L17.6652 16.5447L16.545 17.6649L13.155 14.2742C11.8937 15.2853 10.3248 15.8353 8.70825 15.833C4.77525 15.833 1.58325 12.641 1.58325 8.70801C1.58325 4.77501 4.77525 1.58301 8.70825 1.58301C12.6413 1.58301 15.8333 4.77501 15.8333 8.70801C15.8356 10.3246 15.2856 11.8935 14.2745 13.1548ZM12.6864 12.5674C13.6911 11.5342 14.2522 10.1492 14.2499 8.70801C14.2499 5.64584 11.7696 3.16634 8.70825 3.16634C5.64609 3.16634 3.16659 5.64584 3.16659 8.70801C3.16659 11.7694 5.64609 14.2497 8.70825 14.2497C10.1494 14.2519 11.5344 13.6908 12.5676 12.6861L12.6864 12.5674Z" />
    </svg>
  );
};

export default SearchIcon;
