import hypernova from "hypernova";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";

/**
 *  The counterpart to AssetService.bootstrapData. Calling this will
 *  render your react component to the div created by calling bootstrapData and pass
 *  in any data provided as props.
 */
const renderFromPreloadedJSON = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>,
  componentName: string,
  camelCase = false
) => {
  const serverRendered = hypernova.load(componentName);

  serverRendered.forEach(async ({ node, data }) => {
    let props = data;
    if (camelCase) {
      const { default: camelcaseKeys } = await import("camelcase-keys");
      props = camelcaseKeys(data, { deep: true });
    }

    if (!node.firstElementChild) {
      return hydrateRoot(node, <Component {...props} />);
    }

    const root = createRoot(node);
    return root.render(<Component {...props} />);
  });
};

export default renderFromPreloadedJSON;
