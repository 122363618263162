import { logMessage } from "@wss/error-tracking";
import polyfillNeeded from "./polyfillNeeded";
/**
 * We do a separate check for preload support. Because some modern browsers don't support it
 * we don't want to give them all the polyfills just because they don't support preload.
 */
import("./preloadSupported");

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Window {
    wssModulePolyfillError: string;
  }
}

export default (main: () => void) => {
  if (polyfillNeeded()) {
    logMessage("Uses has a device that needed the polyfills", "Info");
    import("./index")
      .then(() => {
        main();
      })
      .catch((error) => {
        window.wssModulePolyfillError = error;
        // eslint-disable-next-line no-console
        console.log(
          `An error occurred while polyfilling the environment.
          Check window.wssModulePolyfillError in your browsers console for more information.`,
          error
        );
      });
  } else {
    main();
  }
};
