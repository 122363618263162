import { RootAction } from "StoreTypes";
import { getType } from "typesafe-actions";
import { defaultUserObject } from "./index";
import {
  setPlatinumNotifications,
  deletePlatinumNotification,
} from "../../actions/user/platinumNotifications";

const platinumNotificationsReducer = (
  state = defaultUserObject.platinum.notifications,
  action: RootAction
) => {
  switch (action.type) {
    case getType(setPlatinumNotifications):
      return action.payload;
    case getType(deletePlatinumNotification):
      return state.filter((notification) => notification.id !== action.payload);
    default:
      return state;
  }
};

export default platinumNotificationsReducer;
