import { combineReducers } from "redux";
import chatReducer, { defaultChatObject } from "./reducers/chat";
import userReducer, { defaultUserObject } from "./reducers/user";
import logicModalReducer, {
  defaultLoginModalObject,
} from "./reducers/loginModal";
import abFeatureReducer, { defaultAbFeatureObject } from "./reducers/abFeature";

const rootReducer = combineReducers({
  chat: chatReducer,
  user: userReducer,
  loginModal: logicModalReducer,
  abFeature: abFeatureReducer,
});

export {
  defaultUserObject,
  defaultChatObject,
  chatReducer,
  defaultLoginModalObject,
  defaultAbFeatureObject,
};

export const defaultRootObject = {
  user: defaultUserObject,
  chat: defaultChatObject,
  loginModal: defaultLoginModalObject,
  abFeature: defaultAbFeatureObject,
};

export default rootReducer;
