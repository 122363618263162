import React, { lazy, Suspense } from "react";
import useIsMounted from "~/common/useIsMounted";
import CategoryItem from "./CategoryItem";
import FlyoutNavigation from "./FlyoutNavigation";

const importHotKeys = async () => {
  const { HotKeys } = await import("react-hotkeys");
  return { default: HotKeys };
};

const HotKeys = lazy(() => importHotKeys());

export interface FeaturedResource {
  name: string;
  image: string;
  link: string;
  childrenAreProducts: boolean;
}

export interface NavItemData {
  displayName: string;
  cssClassModifier: string;
  link: string;
  moreInText: string;
  featuredResources: FeaturedResource[];
  subResources: FeaturedResource[];
}

interface ItemProps {
  data: NavItemData;
  flyoutIsOpen: boolean;
  expandedFlyout: string;
  handleKeyboardOpen(e?: KeyboardEvent): void;
  mouseEnterMenuItem(displayName: string): void;
}

const keyMap = {
  openFlyout: "enter",
};

export default function Item({
  data,
  flyoutIsOpen,
  expandedFlyout,
  handleKeyboardOpen,
  mouseEnterMenuItem,
}: ItemProps) {
  const isOpenFlyout = expandedFlyout === data.displayName;
  const handlers = {
    openFlyout: handleKeyboardOpen,
  };
  const isMounted = useIsMounted();

  return (
    <>
      <CategoryItem
        link={data.link}
        displayName={data.displayName}
        isOpenFlyout={isOpenFlyout}
        mouseEnterMenuItem={mouseEnterMenuItem}
      />
      {isMounted && (
        <Suspense fallback={null}>
          <HotKeys {...{ keyMap, handlers }} data-testid="hotkeys">
            <button
              type="button"
              className="offscreen"
              aria-expanded={isOpenFlyout}
              data-type={data.displayName}
              data-testid="keyboardButton"
            >
              <span>{data.displayName.replace(/<br ?\/?>/g, "")} Submenu</span>
            </button>
          </HotKeys>
        </Suspense>
      )}
      {flyoutIsOpen && expandedFlyout && (
        <FlyoutNavigation data={data} isOpenFlyout={isOpenFlyout} />
      )}
    </>
  );
}
